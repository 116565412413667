import { Component, OnInit } from '@angular/core';
import { GeneralSettingValue, GeneralSettingValueService, SocietiesService, Society } from '@pwc-ecobonus/common';
import { piDms, piEcobonus, piHome, piMenuConfiguration, piMenuDashboard, piMenuDossier, piMenuTaxCredit, PwcIconsLibrary } from '@pwc-ecobonus/icons';
import { environment } from '../../../../environments/environment';
import { RoutePath } from '../../../enums/route-path.enum';
import { AuthorityCodeEnum } from '../../../enums/security/authority-code.enum';
import {Authority, AuthorizationService} from "@pwc-ecobonus/security";
import { switchMap, tap } from "rxjs/operators";

@Component({
  selector: 'app-nav-menu',
  templateUrl:
    './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  showLabels = true;
  moduleItemCollapsed = true;
  dossierItemCollapsed = true;
  meetingsCollapsed = false;
  masterdataCollapsed = true;
  ownershipdataCollapsed = true;
  configurationCollapsed = true;
  supportRequestCollapsed = true;
  customerInformativeCollapsed = true;
  // live assistant section
  liveAssistantActive = false;
  liveAssistantMenuCollapse = true;
  liveAssistantVCCollapse = true;

  RoutePath = RoutePath;
  AuthorityCodeEnum = AuthorityCodeEnum;

  dashboardActive = false;

  authoritiesByRoute = {
    [RoutePath.DASHBOARD]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_DASHBOARD_READ
    ],
    [RoutePath.DOSSIER]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_DOSSIER_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_DOSSIER_CREATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_DOSSIER_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_DOSSIER_DELETE,
      AuthorityCodeEnum.ROLE_ECOBONUS_DOSSIER_VALIDATE,
    ],
    [RoutePath.PROJECT]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_PROJECT_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_PROJECT_CREATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_PROJECT_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_PROJECT_DELETE,
    ],
    [RoutePath.REPORTS]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_DELIVERY_EXPORT
    ],
    [RoutePath.COMMUNICATION]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_COMMUNICATION_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_COMMUNICATION_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_COMMUNICATION_DELETE,
      AuthorityCodeEnum.ROLE_ECOBONUS_COMMUNICATION_CHANGE_STATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_COMMUNICATION_EXPORT,
    ],
    [RoutePath.CONFIGURATION]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_CREATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_DELETE,
      AuthorityCodeEnum.ROLE_ECOBONUS_HELP_DESK_FAQ,
      AuthorityCodeEnum.ROLE_ECOBONUS_CREATE_PREMIUM_CUSTOMER_CONFIGURATION,
      AuthorityCodeEnum.ROLE_ECOBONUS_UPDATE_PREMIUM_CUSTOMER_CONFIGURATION,
    ],
    [RoutePath.FAQ]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_HELP_DESK_FAQ
    ],
    [RoutePath.FAQ_CATEGORY]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_HELP_DESK_FAQ
    ],
    [RoutePath.TEAM_DELIVERY]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_TEAM_DELIVERY
    ],
    [RoutePath.SUPPORT_TYPE]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_HELP_DESK_FAQ
    ],
    [RoutePath.SUPPORT_REQUEST]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_HELP_DESK_SUPPORT_REQUESTS
    ],
    [RoutePath.SUPPORT_REQUEST_LOGIN]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_HELP_DESK_SUPPORT_REQUESTS
    ],
    [RoutePath.FAQ_LIST]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_ACCESS
    ],
    [RoutePath.TAX_CREDIT]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_TAX_CREDIT_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_TAX_CREDIT_CREATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_TAX_CREDIT_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_TAX_CREDIT_DELETE,
    ],
    [RoutePath.ENTITIES_IMPORTER]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_ENTITIES_IMPORTER
    ],
    dms: [
      AuthorityCodeEnum.ROLE_ECOBONUS_DMS_VIEW,
    ],
    [RoutePath.REGISTRY]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_REGISTRY_CREATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_REGISTRY_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_REGISTRY_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_REGISTRY_DELETE,
    ],
    [RoutePath.OWNERSHIP]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_PWC_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_PWC_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_TECH_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_TECH_UPDATE,
    ],
    [RoutePath.OWNERSHIP_PWC]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_PWC_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_PWC_UPDATE,
    ],
    [RoutePath.OWNERSHIP_TECH]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_TECH_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_OWNERSHIP_TECH_UPDATE,
    ],
    [RoutePath.PRICING_TABLE]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_CREATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_UPDATE,
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_DELETE,
      AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_READ,
    ],
    [RoutePath.BLACKLIST]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_BLACKLIST_VIEW,
      AuthorityCodeEnum.ROLE_ECOBONUS_BLOCK_PROJECT
    ],
    [RoutePath.CUSTOMER_INFORMATIVE]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_CUSTOMER_INFORMATIVE_CONFIGURATION
    ],
    [RoutePath.PREMIUM_CUSTOMER]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_CREATE_PREMIUM_CUSTOMER_CONFIGURATION,
      AuthorityCodeEnum.ROLE_ECOBONUS_UPDATE_PREMIUM_CUSTOMER_CONFIGURATION
    ],
    [RoutePath.INTEGRATION_DASHBOARD]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_INTEGRATION_DASHBOARD_ACCESS
    ],
    [RoutePath.UNIT_INTEGRATION_DASHBOARD]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_INTEGRATION_DASHBOARD_ACCESS
    ],
    [RoutePath.LIVE_ASSISTANT]: [
      AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ,
      AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ_ALL,
      AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ_MYDATA
    ]
  };

  advisory = environment.configuration.advisory;
  customerInformativeConfiguration = environment.configuration.customerInformativeConfiguration;

  faqQueryParams: FaqQueryParams = {};
  canAccessPremiumConfig: boolean = false;

  constructor(
    private pwcIconsLibrary: PwcIconsLibrary,
    private societiesService: SocietiesService,
    private generalSettingValueService: GeneralSettingValueService,
    private authorizationService: AuthorizationService
  ) {
    this.pwcIconsLibrary.registerIcons([
      piEcobonus,
      piHome,
      piDms,
      piMenuDashboard,
      piMenuDossier,
      piMenuConfiguration,
      piMenuTaxCredit
    ]);
  }

  ngOnInit(): void {
    this.liveAssistantActive = environment.configuration.liveAssistantActive;
    this.initDashboard();
    this.authorizationService.getAuthorities()
        .subscribe((res: Authority[]) => {
          this.canAccessPremiumConfig = false;
          const admin = res.filter(e => e.code === AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_UPDATE)?.length > 0;
          const canCreatePremiumConfig = res.filter(e => e.code === AuthorityCodeEnum.ROLE_ECOBONUS_CREATE_PREMIUM_CUSTOMER_CONFIGURATION)?.length > 0;
          const canUpdatePremiumCustomer = res.filter(e => e.code === AuthorityCodeEnum.ROLE_ECOBONUS_UPDATE_PREMIUM_CUSTOMER_CONFIGURATION)?.length > 0;

          if ((admin && canCreatePremiumConfig) || canUpdatePremiumCustomer) {
            this.canAccessPremiumConfig = true;
          }
     });
  }

  private initDashboard() {
    this.societiesService.getSelectedSociety().pipe(
        switchMap((society: Society) => {
          this.faqQueryParams = {
            societyId: society.id
          };
          console.log("Nav bar FAQ queryParam: ", this.faqQueryParams);
          return this.generalSettingValueService.findByCode('ECOBONUS_DASHBOARD_ACTIVE', society.id);
        }),
    ).subscribe((list: GeneralSettingValue[]) => {
          this.dashboardActive = false;
          if (list == null || list.length === 0) {
            return;
          }

          if (list.length > 0) {
            const setting = list[0];
            if (setting.value && (setting.value.toLowerCase() === 'true' || setting.value.toLowerCase() === 'si')) {
              this.dashboardActive = true;
            }
          }
        }
    );
  }

  navigateToFramework(): void {
    self.location.href = environment.frameworkUrl;
  }

  navigateToDms(): void {
    self.location.href = environment.dmsUrl;
  }

  isDynamicFaqActive(): boolean {
    return environment.configuration.dynamicFaq?.active;
  }

  isBlacklistActive(): boolean {
    return environment.configuration.blacklist?.active;
  }

  isHolidaySkipEnabled(): boolean {
    return environment.configuration.holidaySkipEnabled;
  }
}

interface FaqQueryParams {
  societyId?: number;
}
