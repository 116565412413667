import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { ChatbotMessage } from '../../models/chatbot-message';

/**
 * This component is used to display a list of messages.
 * It is used by the chatbot component.
 *
 * @author Mc Ayrton Aragon
 */
@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent implements AfterViewChecked {

  @Input()
  messages: ChatbotMessage[] = [];

  @Output() likeRate = new EventEmitter<{ like: boolean, message: ChatbotMessage }>();
  @Output() dislikeRate = new EventEmitter<{ dislike: boolean, message: ChatbotMessage }>();

  @ViewChild('messagesList')
  private messagesList: ElementRef;

  public scrollTop: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngAfterViewChecked(): void {
    this.scrollTop = this.messagesList.nativeElement.scrollHeight;
    this.changeDetectorRef.detectChanges();
  }

  public onLikePress(eventData: { like: boolean, message: ChatbotMessage }) {
    this.likeRate.emit(eventData);
  }

  public onDislikePress(eventData: { dislike: boolean, message: ChatbotMessage }) {
    this.dislikeRate.emit(eventData);
  }

}
