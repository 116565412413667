<ng-container *ngIf="isChatbotShowAble">
  <!-- Button Section -->
  <button
    class="floating-button"
    (click)="toggleChatSection()"
    *ngIf="!isChatSectionShowed"
    [title]=" !isChatSectionShowed ? tooltipOpeningChatbotPlaceholder : tooltipClosingChatbotPlaceholder"
  >
  </button>
  <button
    class="floating-button-opened"
    (click)="toggleChatSection()"
    *ngIf="isChatSectionShowed"
    data-toggle="tooltip"
    data-placement="left"
    [title]=" !isChatSectionShowed ? tooltipOpeningChatbotPlaceholder : tooltipClosingChatbotPlaceholder"
  >
  </button>
  <!-- Chat Section -->
  <div
    *ngIf="isChatSectionShowed"
    class="floating-chat-section no-gutters"
    [@fadeInOut]="isChatSectionShowed"
  >
    <ng-container *ngIf="!isChatbotOnline">
      <div class="overlay-offline"></div>
      <div class="overlay-offline-modal">{{ chatbotOfflinePlaceholder }}</div>
    </ng-container>
    <div class="">
      <!-- Header -->
      <div class="pt-3 px-3">
        <div class="header-title row" translate>
          <div
            class="col-8"
            [ngClass]="[isToggleChatSectionButtonShowAble ? 'my-auto' : 'mb-2',
             isChatbotMockupModeEnabled ? 'my-auto' : 'mt-1']"
          >
            {{ chatbotTitle }}
          </div>
          <div class="col-4">
            <button
              *ngIf="isToggleChatSectionButtonShowAble"
              type="button"
              id="model-toggle"
              class="btn btn-primary mb-1"
              (click)="changeChatMode()"
            >
              {{ chatMode.toUpperCase() }}
            </button>
            <div class="dropdown mb-1"
                 *ngIf="isChatbotMockupModeEnabled">
              <div ngbDropdown class="d-inline">
                <button ngbDropdownToggle class="btn btn-ghost quote-action"
                        data-toggle="tooltip"
                        data-placement="left"
                        [title]="tooltipChatbotEnvironmentPlaceholder">
                  {{ chatbotMockupMode }}
                </button>
                <div ngbDropdownMenu aria-labelledby="actionMenu">
                  <ng-container *ngFor="let mockupMode of chatbotMockupModes">
                    <button (click)="changeMockupMode(mockupMode)"
                            class="btn ml-auto orange-text align-self-end text-nowrap" ngbDropdownItem>
                      {{ mockupMode }}
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-secondary-title pt-2 pb-2">
          {{ chatbotSecondaryTitle }}
        </div>
      </div>

      <!-- Messages section -->
      <div class="card-body">
        <app-messages-list
          [messages]="messages"
          (likeRate)="onLikePress($event)"
          (dislikeRate)="onDislikePress($event)"
        ></app-messages-list>
      </div>

      <!-- Form Section -->
      <div class="align-items-center p-3">
        <app-submission-form
          (sendMessageEvent)="sendMessage($event)"
          [isInputWaitingForAnswer]="!isMessageAnswered"
          [isInputDisabled]="!isChatbotOnline"
        >
        </app-submission-form>
      </div>
    </div>
  </div>
</ng-container>
