import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component is used to display a form to submit a message.
 * It is used by the chatbot component.
 *
 * @author Mc Ayrton Aragon
 */
@Component({
  selector: 'app-submission-form',
  templateUrl: './submission-form.component.html',
  styleUrls: ['./submission-form.component.scss']
})
export class SubmissionFormComponent {

  @Output()
  private sendMessageEvent = new EventEmitter<string>();

  @Input()
  public isInputWaitingForAnswer: boolean = false;

  @Input()
  public isInputDisabled: boolean = false;

  public formPlaceholder: string;

  constructor(
    private translateService: TranslateService,
  ) {
    this.formPlaceholder = this.translateService.instant('chatbot.action.input.placeholder');
  }

  /**
   * This method is called when the user submit the form.
   * @param form the form to submit
   */
  public onSubmit(form: NgForm) {
    if (this.isInputWaitingForAnswer) {
      return;
    }
    const message: string = form.value['message-text'];
    form.reset();
    if (message.length !== 0) {
      this.sendMessageEvent.emit(message);
    }
  }

}
