/**
 * This enum is used to define the model language used by the chatbot.
 *
 * @author Mc Ayrton Aragon
 */
export enum OpenAIChatbotMockupModeEnum {
  UNICREDIT = 'UNICREDIT',
  BPER = 'BPER',
  CARIGE = 'CARIGE',
  OTHER_BANK = 'OTHER_BANK',
  //BDS = 'BDS',
  ALL = 'ALL'
}

export const OpenAIChatbotMockupModeEnumMapping = {
  [OpenAIChatbotMockupModeEnum.UNICREDIT]: 'Unicredit',
  [OpenAIChatbotMockupModeEnum.BPER]: 'BPER',
  [OpenAIChatbotMockupModeEnum.CARIGE]: 'Carige',
  [OpenAIChatbotMockupModeEnum.OTHER_BANK]: 'Altre banche',
  //[OpenAIChatbotMockupModeEnum.BDS]: 'BDS',
  [OpenAIChatbotMockupModeEnum.ALL]: 'All'
};
