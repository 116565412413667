<div
  id="message-{{message.isUserMessage ? 'user' : 'bot'}}"
  class="message"
  style="padding-bottom: 8px"
  [ngClass]="message.isUserMessage ? 'user-message' : 'bot-message'"
>
  <img
    *ngIf="!message.isUserMessage"
    src="/assets/images/logo_pwc.svg"
    class="logo col-1"
    alt="avatar chatbot"
    style="width: 40px; height: 100%; padding: 4px;"
  />
  <img
    *ngIf="message.isUserMessage"
    src="/assets/images/request-user.svg"
    class="logo col-1"
    alt="avatar chatbot"
    style="width: 40px; height: 100%; padding: 4px;"
  />
  <div
    class="ms-3"
    style="border-radius: 15px; max-width: 260px; padding: 4px 16px;"
    [ngClass]="message.isUserMessage ? 'user-message-text' : 'bot-message-text'"
  >
    <div *ngIf="!message.isUserMessage && message.isBotThinking">
      <app-chatbot-typing-indicator></app-chatbot-typing-indicator>
    </div>
    <ng-container *ngIf="!message.isBotThinking">
      <p class="mb-0">
        {{ message.text }}
        <a
          *ngIf="message.linkUrl"
          target="_blank"
          rel="noreferrer noopener"
          [href]="message.linkUrl"
        ><u>{{ message.linkText }}</u>.
        </a>
      </p>
      <p class="mb-0" style="text-align: right">
        <ng-container *ngIf="!message.isUserMessage && message.isRatingEnabled">
          <img
            alt="chatbot chat like"
            [src]="checkLikeIcon()"
            class="mb-1 mr-1"
            style="width: 13px; height: 13px; margin-bottom: 4px; margin-right: 4px;"
            (click)="likePress()"
            data-toggle="tooltip"
            data-placement="top"
            [title]="likePlaceholder"
          />
          <img
            alt="chatbot chat dislike"
            [src]="checkDislikeIcon()"
            class="mb-1 ml-1"
            style="width: 13px; height: 13px; margin-bottom: 4px; margin-right: 4px;"
            (click)="dislikePress()"
            data-toggle="tooltip"
            data-placement="top"
            [title]="dislikePlaceholder"
          />
        </ng-container>
        <a
          *ngIf="!message.isUserMessage && message.isLinkToReportEnabled"
          target="_blank"
          rel="noreferrer noopener"
          [href]="message.isLinkToReportMessage"
          class="ml-2"
        >
          <img
            src="/assets/images/icons/chatbot-circle-question-regular.svg"
            class="mb-1"
            alt="chatbot chat question"
            style="width: 13px; height: 13px; margin-bottom: 4px; margin-right: 4px;"
            data-toggle="tooltip"
            data-placement="top"
            [title]="tooltipPlaceholder"
          />
        </a>
      </p>
    </ng-container>
  </div>
</div>
