import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '@pwc-ecobonus/common';
import {AuthenticationService} from '@pwc-ecobonus/security';
import {RoutePath} from 'src/app/enums/route-path.enum';
import {BasePage} from '../base-page/base-page';
import {environment} from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: environment.configuration.region ? './terms-and-conditions-region.component.html' : './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent extends BasePage implements OnInit {

  termsAccepted: boolean = false;
  user: User = null;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private navigation: NavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authenticationService.dataUser()
      .subscribe((user: User) => {
        this.user = user;

      });
  }

  back(): void {
    this.navigation.back()
  }

  acceptTerms() {
    // save

    //when saved
    this.router.navigate([RoutePath.SLASH]);
  }
}
