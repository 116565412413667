<div class="card full-card shadow-sm my-4 card-v-center">
   <div class="card-body">
      <div class="col-6 mx-auto" style="color:black">

         <h2>Termini e Condizioni per l’accesso alla Piattaforma</h2>
         <br>
         <p>
            I presenti Termini e Condizioni disciplinano l’accesso, la registrazione e l’utilizzo della Piattaforma
            (come nel
            prosieguo definita) da parte degli Utenti (nel prosieguo anch’essi definiti). Accedendo o utilizzando la
            Piattaforma, l'Utente accetta di aver letto, compreso e accettato i presenti Termini e Condizioni. Pertanto,
            si
            invitano tutti gli Utenti a leggere con attenzione i presenti Termini e Condizioni prima di accedere,
            registrarsi
            e utilizzare la Piattaforma.
         </p>
         <p>
            L’accesso e l’utilizzo della Piattaforma costituiscono automatica, piena e incondizionata accettazione dei
            presenti Termini e Condizioni.
         </p>
         <p class="paragraph">
            <b>1. Definizioni</b>
         </p>
         <p>
            <b>Accordo</b>: l’accordo sottoscritto tra PwC TLS ed il cliente.
         </p>

         <p>
            <b>Beneficiari</b>: destinatari finali dell’incentivo fiscale in materia di efficientamento energetico e
            riduzione
            del rischio sismico degli edifici disciplinato dal Decreto Rilancio o Leggi speciali in materia, ed a cui
            fanno
            riferimento i documenti caricati nella Piattaforma ai fini delle valutazioni svolte dai professionisti di
            PwC TLS.
         </p>

         <p>
            <b>Decreto Rilancio</b>: d.l. 19 maggio 2020, n. 34, recante “Misure urgenti in materia di salute, sostegno
            al
            lavoro e all'economia, nonché di politiche sociali connesse all'emergenza epidemiologica da COVID-19”,
            convertito
            con modificazioni dalla L. 17 luglio 2020, n. 77.
         </p>

         <p>
            <b>Piattaforma</b> : la presente piattaforma, in titolarità di PwC Services e di cui PwC TLS ha acquisito il
            diritto d’uso, raggiungibile al sito internet [ecobonus.e001.digitalsuite.pwc-tls.it].
         </p>

         <p>
            <b>PwC TLS</b> : TLS Associazione Professionale di Avvocati e Commercialisti, Piazza Tre Torri, 2, 20145,
            Milano
            (MI).
         </p>

         <p>
            <b>PwC Services</b>: PricewaterhouseCoopers Services S.r.l., Piazza Tre Torri, 2, 20145, Milano (MI).
         </p>
         <p>
            <b>Soggetti Terzi</b>: le ulteriori categorie di soggetti cui possono fare riferimento le informazioni e i
            dati
            personali comunicati tramite la Piattaforma per l’esecuzione dell’Accordo. I Soggetti Terzi includono, ad
            esempio,
            le seguenti categorie di soggetti: i titolari o detentori degli immobili, i referenti di progetto, i
            fornitori dei
            beni e servizi edilizi agevolabili fiscalmente, gli amministratori di condominio e/o i condomini.
         </p>
         <p>
            <b>Utenti</b> utenti che utilizzano la Piattaforma. Gli Utenti possono anche non coincidere con i
            Beneficiari o
            con i clienti che hanno sottoscritto l’Accordo, potendo consistere nei Soggetti Terzi, come sopra definiti.
         </p>

         <p class="paragraph">
            <b>2. Premesse e oggetto</b>
         </p>
         <p>
            PwC TLS svolge la valutazione della documentazione richiesta dal Decreto Rilancio e dai provvedimenti
            attuativi,
            ovvero dalle Leggi che regolano i singoli incentivi fiscali in materia di efficientamento energetico e di
            riduzione di rischio sismico degli edifici. Ciò al fine dello svolgimento dei servizi convenuti con i propri
            clienti nell’ambito dell’Accordo. La Piattaforma ha lo scopo di agevolare l’invio delle e l’accesso alle
            informazioni e documenti necessari all’esecuzione dei servizi oggetto dell’Accordo.
         </p>
         <p>
            A questo proposito, gli Utenti potranno accedere alla Piattaforma per caricare tali informazioni e
            documenti.
         </p>

         <p>
            L’utilizzo della Piattaforma è riservato agli Utenti. In particolare, tramite la Piattaforma, gli Utenti
            potranno
            registrarsi e caricare la documentazione richiesta, che sarà valutata dai professionisti di PwC TLS al fine
            dell’esecuzione dei servizi oggetto dell’Accordo.
         </p>

         <p class="paragraph">
            <b>3. Modalità di funzionamento della Piattaforma e registrazione</b>
         </p>
         <p>
            Per utilizzare la Piattaforma, ogni Utente deve registrarsi creando un account personale. In fase di
            registrazione, l’Utente si impegna a fornire dati corretti e completi, nonché a comunicare tempestivamente
            eventuali modifiche dei dati forniti.
         </p>
         <p>
            Una volta terminata la fase di registrazione, l’Utente riceverà le credenziali di accesso alla Piattaforma,
            che si
            impegna a mantenere riservate. Inoltre, l’Utente si impegna a informare PwC TLS qualora ritenga che le
            proprie
            credenziali siano state compromesse ed a procedere alla relativa modifica accedendo nella propria area
            riservata
            presente nella Piattaforma.
         </p>
         <p>
            Effettuato l’accesso alla Piattaforma, l’Utente potrà procedere al caricamento dei documenti relativi a uno
            o più
            Beneficiari necessari per l’esecuzione dei servizi oggetto dell’Accordo.
         </p>
         <p>
            L’Utente accetta di essere considerato l’unico e il solo responsabile per tutte le azioni poste in essere
            per il
            tramite
            del proprio account personale, nonché di ogni conseguenza dannosa o pregiudizio che possa derivare a carico
            di PwC
            TLS
            e/o di altre entità del network PwC o di terzi a seguito di un utilizzo del proprio account personale in
            violazione
            della legge e dei presenti Termini e Condizioni.
         </p>
         <p>
            Qualora l’account personale dell’Utente venga utilizzato in violazione della legge o dei presenti Termini e
            Condizioni,
            il medesimo potrà essere sospeso, modificato o cancellato.
         </p>

         <p class="paragraph">
            <b>4. Condizioni d’uso della Piattaforma: dichiarazioni e obblighi dell’Utente</b>
         </p>
         L’utilizzo della Piattaforma è consentito esclusivamente alle persone di maggiore età.
         <p>
            Nell’utilizzo della Piattaforma, l’Utente si impegna a:
         </p>
         <ul>
            <li>utilizzare, in fase di registrazione alla Piattaforma e per ogni successivo caricamento dei documenti
               informazioni personali accurate;</li>
            <li>non comunicare informazioni false e/o non corrette;</li>
            <li>non caricare informazioni e/o dati personali relativi a soggetti terzi, inclusi i Beneficiari, senza che
               questi
               abbiano espresso il loro consenso;</li>
            <li>non caricare materiali, contenuti e file di qualsiasi tipo in violazione della legge o di diritti di
               terzi,
               inclusi i diritti di proprietà intellettuale e industriale, ed inclusi quelli che presentino un carattere
               osceno,
               intimidatorio, offensivo, dannoso, violento o fraudolento;</li>
            <li>utilizzare la Piattaforma nel rispetto di tutte le leggi vigenti, della morale e dell’ordine pubblico;
            </li>
            <li>non caricare materiali e file pericolosi, quali ad esempio virus informatici, malware o codici che
               possano
               danneggiare o sovraccaricare la Piattaforma o i sistemi informatici di terzi;</li>
            <li>non accedere o raccogliere le informazioni presenti sulla Piattaforma a cui non ha l’autorizzazione ad
               accedere,
               anche tramite strumenti automatizzati;</li>
            <li>non interferire con il normale funzionamento della Piattaforma;</li>
            <li>non copiare, riprodurre, modificare, danneggiare la Piattaforma e ogni contenuto ivi presente; </li>
            <li>custodire e mantenere segrete le proprie credenziali di accesso;</li>
            <li>rispettare tutti i diritti di proprietà intellettuale e industriale di PwC Services, di PwC TLS e di
               terzi,
               anche
               nel rispetto di quanto indicato nel successivo paragrafo 6.</li>
         </ul>
         <p>
            Accedendo e utilizzando la Piattaforma, l’Utente concede a PwC TLS il diritto di utilizzare tutte le
            informazioni
            fornite per lo svolgimento dei servizi richiesti e per ogni eventuale ulteriore obbligo normativo gravante
            su PwC
            TLS.
            Inoltre, l’Utente garantisce che, qualora le informazioni facciano riferimento a Beneficiari e/o Soggetti
            Terzi
            diversi
            dall’Utente stesso o, in ogni caso, ad altri soggetti, ha acquisito l’autorizzazione di questi ultimi a
            fornire le
            informazioni a PwC TLS per l’esecuzione dell’Accordo.
         </p>
         <p>
            PwC TLS si riserva il diritto di rimuovere contenuti che violino i presenti Termini e Condizioni nonché il
            diritto
            di
            interrompere, sospendere e revocare l’accesso e l’utilizzo della Piattaforma all’Utente che non dovesse
            rispettare
            i
            presenti Termini e Condizioni, anche sulla base di valutazioni discrezionali e senza alcun obbligo di
            motivare la
            propria decisione.
         </p>

         <p class="paragraph">
            <b>6. Garanzie e responsabilità</b>
         </p>
         <p>
            PwC Services e PwC TLS non garantiscono in alcun modo e non potranno essere ritenute responsabili per:
         </p>
         <ul>
            <li>la corrispondenza della Piattaforma alle aspettative e/o esigenze dell’Utente; </li>
            <li>i contenuti caricati dagli Utenti in violazione dei presenti Termini e Condizioni, ivi inclusi i
               contenuti
               falsi e in violazione di diritti IP di terzi; </li>
            <li>eventuali malfunzionamenti della Piattaforma non dovuti a PwC Services e/o a PwC TLS; </li>
            <li>alcun danno che l’Utente possa ricevere a causa dell’utilizzo della Piattaforma e/o da interruzioni
               della
               funzionalità della Piattaforma;</li>
            <li>cause di forza maggiore. </li>
         </ul>

         <p>
            L’Utente manleva PwC TLS e le altre entità del network PwC da qualsiasi pretesa avanzata nei propri
            confronti dal
            cliente dell’Accordo, dal Beneficiario, dai Soggetti Terzi nonché da qualsivoglia terzo in genere per fatto
            dovuto
            all’Utente in relazione all’utilizzo della Piattaforma ed ai contenuti caricati in violazione dei presenti
            Termini
            e Condizioni, ivi inclusi contenuti falsi ed in violazione di diritti di terzi.
         </p>

         <p class="paragraph">
            <b>6. Diritti di proprietà intellettuale e industriale </b>
         </p>
         <p>
            Nulla in questi Termini e Condizioni costituisce una licenza di diritti di proprietà intellettuale e
            industriale a
            favore dell’Utente con riferimento alla Piattaforma o a tutti i materiali ivi contenuti, caricati o
            trasferiti, inclusi,
            a titolo esemplificativo, immagini, loghi, grafiche, software, marchi, brevetti, diritti d’autore,
            fotografie, musiche,
            suoni, video, disegni e ogni altro diritto di proprietà intellettuale e industriale. L’utilizzo della
            Piattaforma non
            comporta l’acquisto per l’Utente di alcun diritto.
         </p>
         <p>
            L’Utente si impegna a non modificare, riprodurre, pubblicare, trasferire, diffondere o altrimenti
            utilizzare, in
            qualsiasi forma e modo, i materiali contenuti o relativi alla Piattaforma.
         </p>
         <p>
            Il caricamento sulla Piattaforma non comporta l’acquisizione di alcun diritto sui materiali e informazioni
            caricati
            dagli Utenti. A questo proposito, con riferimento ai materiali caricati dall’Utente, tra cui, a titolo
            esemplificativo,
            immagini, loghi, grafiche, software, marchi, brevetti, diritti d’autore, fotografie, musiche, suoni, video e
            disegni,
            l’Utente manleva PwC TLS e le altre entità del network PwC da qualunque danno o pretesa di terzi, inclusi i
            clienti
            dell’Accordo, i Beneficiari ed i Soggetti Terzi che possano avanzare alla luce del contenuto e qualità del
            materiale.
         </p>

         <p class="paragraph">
            <b>7. Protezione dei dati personali</b>
         </p>
         <p>
            Al fine di consentire l’accesso alla Piattaforma, PwC TLS necessita di trattare i dati personali degli
            Utenti, dei clienti dell’Accordo, dei Beneficiari e dei Soggetti Terzi cui eventualmente fanno riferimento i
            documenti caricati dagli Utenti nella Piattaforma.
         </p>
         <p>
            PwC TLS tratterà tali dati personali in qualità di titolare del trattamento e nel rispetto del Regolamento
            (UE) 2016/679, del d.lgs. 196/2003, come da ultimo modificato dal d.lgs. 101/2018, e di ogni altra
            disposizione applicabile in materia di protezione dei dati personali. Per tutte le informazioni sul
            trattamento dei dati personali da parte di PwC TLS e sui diritti che gli Utenti, i Beneficiari e i Soggetti
            Terzi potranno esercitare in qualità di interessati si rimanda alla Privacy Policy.
         </p>

         <p class="paragraph">
            <b>8. Legge applicabile e foro competente</b>
         </p>
         <p>
            I presenti Termini e Condizioni sono regolati dalla legge italiana.
         </p>
         <p>
            Qualunque controversia che dovesse insorgere relativamente ai presenti Termini e Condizioni sarà di
            competenza esclusiva del Foro di Milano.
            </p>

            <p class="paragraph">
               <b>9. Modifiche dei presenti Termini e Condizioni</b>
         </p>
         <p>
            PwC TLS si riserva il diritto di modificare i presenti Termini e Condizioni, anche alla luce di novità
            normative.
         </p>
         <p>
            L’Utente sarà di volta in volta soggetto ai Termini e Condizioni vigenti nel momento in cui utilizza la
            Piattaforma. È pertanto responsabilità dell’Utente verificare periodicamente i presenti Termini e
            Condizioni.
         </p>

         <div class="mt-4 mb-4 row d-flex flex-row justify-content-center">

            <a (click)="back()" class="btn btn-primary btn-rounded text-nowrap pointer" style="width: 150px">
               <span class="d-inline-block" translate>
                  actions.back
               </span>
            </a>
         </div>

      </div>
   </div>

</div>
