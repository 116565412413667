<div
  style="
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
    position: relative;
  "
>
  <main style="flex: 1">
    <li class="pointer mainActive">
      <a
        (click)="moduleItemCollapsed = !moduleItemCollapsed"
        [attr.aria-expanded]="!moduleItemCollapsed"
        aria-controls="collapseCommunico"
      >
        <pwc-icon name="ecobonus"></pwc-icon>
        <span *ngIf="showLabels" translate>menu.label</span>
      </a>
      <ul
        class="list-unstyled list-2liv"
        [ngbCollapse]="moduleItemCollapsed"
        id="collapseModuleItem"
      >
        <li>
          <a [routerLink]="" (click)="navigateToFramework()">
            <pwc-icon name="home"></pwc-icon>
            <span *ngIf="showLabels" translate>menu.submenu</span>
          </a>
        </li>
        <hr />
      </ul>
    </li>

    <ng-container *ngIf="dashboardActive">
      <li
        routerLinkActive="active"
        *canAccess="authoritiesByRoute[RoutePath.DASHBOARD]"
      >
        <a [routerLink]="RoutePath.SLASH + RoutePath.DASHBOARD">
          <pwc-icon name="menu_dashboard"></pwc-icon>
          <span *ngIf="showLabels" translate>menu.dashboard.main-menu</span>
        </a>
      </li>
    </ng-container>

    <li routerLinkActive="active">
      <a [routerLink]="RoutePath.SLASH + RoutePath.PROJECT">
        <img src="/assets/images/icons/menu/projects.svg" alt="Progetti" />
        <span *ngIf="showLabels" translate>menu.dossier.project-list</span>
      </a>
    </li>

    <li routerLinkActive="active" *ngIf="advisory">
      <a [routerLink]="RoutePath.SLASH + RoutePath.ADVISORY">
        <img src="/assets/images/icons/menu/projects.svg" alt="Advisory" />
        <span *ngIf="showLabels" translate>menu.advisory.main-menu</span>
      </a>
    </li>

    <li
      routerLinkActive="active"
      *canAccess="authoritiesByRoute[RoutePath.COMMUNICATION]"
    >
      <a [routerLink]="RoutePath.SLASH + RoutePath.COMMUNICATION">
        <img src="/assets/images/icons/menu/dossier.svg" alt="Comunicazioni" />
        <span *ngIf="showLabels" translate
          >menu.dossier.communication-list</span
        >
      </a>
    </li>

    <li
      routerLinkActive="active"
      *canAccess="authoritiesByRoute[RoutePath.REPORTS]"
    >
      <a [routerLink]="RoutePath.SLASH + RoutePath.REPORTS">
        <img src="/assets/images/icons/menu/dossier.svg" alt="Reportistica" />
        <span *ngIf="showLabels" translate>menu.dossier.reports</span>
      </a>
    </li>
    <ng-container *ngIf="liveAssistantActive">
      <li
        class="pointer"
        routerLinkActive="active"
        *canAccess="authoritiesByRoute[RoutePath.LIVE_ASSISTANT]"
      >
        <a
          (click)="liveAssistantMenuCollapse = !liveAssistantMenuCollapse"
          [attr.aria-expanded]="!liveAssistantMenuCollapse"
          aria-controls="liveAssistantSubmenu"
        >
          <img
            src="/assets/images/icons/menu/videoconference.svg"
            alt="Live Assistant"
          />
          <span *ngIf="showLabels" translate
            >menu.live-assistant.main-menu</span
          >
        </a>
        <ul
          class="list-unstyled list-2liv"
          [ngbCollapse]="liveAssistantMenuCollapse"
          id="liveAssistantSubmenu"
        >
          <li
            routerLinkActive="active"
            *canAccess="[AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ]"
          >
            <a
              [routerLink]="
                RoutePath.LIVE_ASSISTANT +
                RoutePath.SLASH +
                RoutePath.LIVE_ASSISTANT_CONFIGURATION
              "
            >
              <img
                src="/assets/images/icons/menu/meetings/ic_meeting_config.svg"
                alt="Configurazione Videoconferenza"
              />
              <span *ngIf="showLabels" translate
                >menu.live-assistant.configuration-menu</span
              >
            </a>
          </li>
          <li
            class="pointer"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            *canAccess="[
              AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ,
              AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ_ALL,
              AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ_MYDATA
            ]"
          >
            <a
              (click)="liveAssistantVCCollapse = !liveAssistantVCCollapse"
              [attr.aria-expanded]="!liveAssistantVCCollapse"
              aria-controls="videoconferenceMonitoringSubmenu"
            >
              <img
                src="/assets/images/icons/menu/meetings/ic_meeting_list.svg"
                alt="Monitoraggio Videoconferenza"
              />
              <span *ngIf="showLabels" translate
                >menu.live-assistant.monitoring-menu</span
              >
            </a>
            <ul
              class="list-unstyled list-2liv"
              [ngbCollapse]="liveAssistantVCCollapse"
              id="videoconferenceMonitoringSubmenu"
            >
              <li
                routerLinkActive="active"
                *canAccess="[AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ_MYDATA]"
              >
                <a
                  style="padding-left: 50px !important"
                  [routerLink]="
                    RoutePath.LIVE_ASSISTANT +
                    RoutePath.SLASH +
                    RoutePath.LIVE_ASSISTANT_MONITORING +
                    RoutePath.SLASH +
                    RoutePath.MY_VIDEOCALL
                  "
                >
                  <img
                    src="/assets/images/icons/menu/ic_meetings.svg"
                    alt="Le mie Videoconferenze"
                  />
                  <span *ngIf="showLabels" translate
                    >menu.live-assistant.my-videocall</span
                  >
                </a>
              </li>
              <li
                routerLinkActive="active"
                *canAccess="[AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ]"
              >
                <a
                  style="padding-left: 50px !important"
                  [routerLink]="
                    RoutePath.LIVE_ASSISTANT +
                    RoutePath.SLASH +
                    RoutePath.LIVE_ASSISTANT_MONITORING +
                    RoutePath.SLASH +
                    RoutePath.VIDEOCALL_CREATED
                  "
                >
                  <img
                    src="/assets/images/icons/menu/videoconference.svg"
                    alt="Le mie Videoconferenze"
                  />
                  <span *ngIf="showLabels" translate
                    >menu.live-assistant.videocall-created</span
                  >
                </a>
              </li>
              <li
                routerLinkActive="active"
                *canAccess="[AuthorityCodeEnum.ROLE_ECOBONUS_VC_READ_ALL]"
              >
                <a
                  style="padding-left: 50px !important"
                  [routerLink]="
                    RoutePath.LIVE_ASSISTANT +
                    RoutePath.SLASH +
                    RoutePath.LIVE_ASSISTANT_MONITORING +
                    RoutePath.SLASH +
                    RoutePath.VIDEOCALL_HISTORY
                  "
                >
                  <pwc-icon name="menu_configuration"></pwc-icon>
                  <span *ngIf="showLabels" translate
                    >menu.live-assistant.videocall-recap</span
                  >
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ng-container>

    <ng-container *ngIf="isDynamicFaqActive()">
      <li
        class="pointer"
        routerLinkActive="active"
        *canAccess="authoritiesByRoute[RoutePath.SUPPORT_REQUEST]"
      >
        <a
          (click)="supportRequestCollapsed = !supportRequestCollapsed"
          [attr.aria-expanded]="!supportRequestCollapsed"
          aria-controls="supportRequestSubmenu"
        >
          <img src="/assets/images/icons/menu/projects.svg" alt="LIST" />
          <span *ngIf="showLabels" translate
            >menu.support-request.parent-list</span
          >
        </a>
        <ul
          class="list-unstyled list-2liv"
          [ngbCollapse]="supportRequestCollapsed"
          id="supportRequestSubmenu"
        >
          <ng-container>
            <li routerLinkActive="active">
              <a
                [routerLink]="
                  RoutePath.SLASH +
                  RoutePath.SUPPORT_REQUEST +
                  RoutePath.SLASH +
                  RoutePath.LIST
                "
              >
                <img
                  class="image"
                  src="/assets/images/icons/menu/projects.svg"
                  alt="LIST"
                />
                <span *ngIf="showLabels" translate
                  >menu.support-request.list</span
                >
              </a>
            </li>
            <li routerLinkActive="active">
              <a
                [routerLink]="
                  RoutePath.SLASH +
                  RoutePath.SUPPORT_REQUEST_LOGIN +
                  RoutePath.SLASH +
                  RoutePath.LIST
                "
              >
                <img
                  class="image"
                  src="/assets/images/icons/menu/projects.svg"
                  alt="LIST"
                />
                <span *ngIf="showLabels" translate
                  >menu.support-request.access-problem</span
                >
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>

    <li
      class="pointer"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      *canAccess="authoritiesByRoute[RoutePath.CONFIGURATION]"
    >
      <a
        (click)="configurationCollapsed = !configurationCollapsed"
        [attr.aria-expanded]="!configurationCollapsed"
        aria-controls="configurationSubmenu"
      >
        <pwc-icon name="menu_configuration"></pwc-icon>
        <span *ngIf="showLabels" translate>menu.configuration.main-menu</span>
      </a>
      <ul
        class="list-unstyled list-2liv"
        [ngbCollapse]="configurationCollapsed"
        id="configurationSubmenu"
      >
        <ng-container
          *canAccess="[
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_READ,
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_CREATE,
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_UPDATE,
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_DELETE
          ]"
        >
          <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.TEMPLATES
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >menu.configuration.checklists</span
              >
            </a>
          </li>
          <li routerLinkActive="active">
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.HISTORY_ANONYMIZATION
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >Anagrafica anonimizzazione storico</span
              >
            </a>
          </li>
          <li routerLinkActive="active">
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.DOCUMENTS
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >menu.configuration.documents</span
              >
            </a>
          </li>
          <li routerLinkActive="active">
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.CHECKLIST_DOCUMENT
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >menu.configuration.checklist-document</span
              >
            </a>
          </li>
          <li
            routerLinkActive="active"
            *canAccess="authoritiesByRoute[RoutePath.PRICING_TABLE]"
          >
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.PRICING_TABLE
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >menu.configuration.pricing-tables</span
              >
            </a>
          </li>
          <li routerLinkActive="active">
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.TARIFF
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >menu.configuration.tariff</span
              >
            </a>
          </li>
          <li routerLinkActive="active">
            <a
              [routerLink]="
                RoutePath.SLASH +
                RoutePath.CONFIGURATION +
                RoutePath.SLASH +
                RoutePath.SAL_YEAR
              "
            >
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate
                >menu.configuration.sal-years</span
              >
            </a>
          </li>
          <li *ngIf="isHolidaySkipEnabled()" routerLinkActive="active">
            <a [routerLink]="RoutePath.SLASH + RoutePath.CONFIGURATION + RoutePath.SLASH + RoutePath.HOLIDAY_SKIP">
              <pwc-icon name="menu_configuration"></pwc-icon>
              <span *ngIf="showLabels" translate>menu.configuration.holiday-skip</span>
            </a>
          </li>
        </ng-container>
        <li
          routerLinkActive="active"
          *canAccess="authoritiesByRoute[RoutePath.FAQ]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.CONFIGURATION +
              RoutePath.SLASH +
              RoutePath.FAQ
            "
          >
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate>menu.configuration.faq</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          *canAccess="authoritiesByRoute[RoutePath.FAQ_CATEGORY]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.CONFIGURATION +
              RoutePath.SLASH +
              RoutePath.FAQ_CATEGORY
            "
          >
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate
              >menu.configuration.faq-categories</span
            >
          </a>
        </li>
        <li
          routerLinkActive="active"
          *canAccess="authoritiesByRoute[RoutePath.SUPPORT_TYPE]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.CONFIGURATION +
              RoutePath.SLASH +
              RoutePath.SUPPORT_TYPE
            "
          >
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate
              >menu.configuration.support-type</span
            >
          </a>
        </li>
        <li
          routerLinkActive="active"
          *canAccess="[
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_READ,
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_CREATE,
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_UPDATE,
            AuthorityCodeEnum.ROLE_ECOBONUS_CONFIG_DELETE
          ]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.CONFIGURATION +
              RoutePath.SLASH +
              RoutePath.OPERATIONS
            "
          >
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate>menu.master-data.operation</span>
          </a>
        </li>

        <li
          routerLinkActive="active"
          *canAccess="authoritiesByRoute[RoutePath.TEAM_DELIVERY]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.CONFIGURATION +
              RoutePath.SLASH +
              RoutePath.TEAM_DELIVERY
            "
          >
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate
              >menu.master-data.team-delivery</span
            >
          </a>
        </li>

        <li
          class="pointer"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *canAccess="authoritiesByRoute[RoutePath.CUSTOMER_INFORMATIVE]"
        >
          <a
            (click)="
              customerInformativeCollapsed = !customerInformativeCollapsed
            "
            [attr.aria-expanded]="!customerInformativeCollapsed"
            aria-controls="informativeSubmenu"
          >
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate
              >menu.customer-informative-configuration</span
            >
          </a>
          <ul
            class="list-unstyled list-2liv"
            [ngbCollapse]="customerInformativeCollapsed"
            id="informativeSubmenu"
          >
            <li routerLinkActive="active">
              <a
                style="padding-left: 50px !important"
                [routerLink]="
                  RoutePath.SLASH +
                  RoutePath.CONFIGURATION +
                  RoutePath.SLASH +
                  RoutePath.CUSTOMER_INFORMATIVE +
                  RoutePath.SLASH +
                  RoutePath.CUSTOMER_POPUP
                "
              >
                <pwc-icon name="menu_configuration"></pwc-icon>
                <span *ngIf="showLabels" translate>menu.popup-list</span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a
                style="padding-left: 50px !important"
                [routerLink]="
                  RoutePath.SLASH +
                  RoutePath.CONFIGURATION +
                  RoutePath.SLASH +
                  RoutePath.CUSTOMER_INFORMATIVE +
                  RoutePath.SLASH +
                  RoutePath.CUSTOMER_BANNER
                "
              >
                <pwc-icon name="menu_configuration"></pwc-icon>
                <span *ngIf="showLabels" translate>menu.banner-list</span>
              </a>
            </li>
          </ul>
        </li>

        <li class="pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            *ngIf="canAccessPremiumConfig">
          <a [routerLink]="RoutePath.SLASH + RoutePath.CONFIGURATION + RoutePath.SLASH + RoutePath.PREMIUM_CUSTOMER">
            <pwc-icon name="menu_configuration"></pwc-icon>
            <span *ngIf="showLabels" translate>menu.premium-customer-configuration</span>
          </a>
        </li>

      </ul>
    </li>

    <li
      routerLinkActive="active"
      *canAccess="authoritiesByRoute[RoutePath.ENTITIES_IMPORTER]"
    >
      <a
        [routerLink]="
          RoutePath.SLASH +
          RoutePath.CONFIGURATION +
          RoutePath.SLASH +
          RoutePath.TEMPLATES +
          RoutePath.SLASH +
          RoutePath.ENTITIES_IMPORTER
        "
      >
        <pwc-icon name="menu_configuration"></pwc-icon>
        <span *ngIf="showLabels" translate>Tool di importazione</span>
      </a>
    </li>

    <li
      class="pointer"
      routerLinkActive="mainActive"
      *canAccess="authoritiesByRoute[RoutePath.OWNERSHIP]"
    >
      <a
        (click)="ownershipdataCollapsed = !ownershipdataCollapsed"
        [attr.aria-expanded]="!ownershipdataCollapsed"
        aria-controls="ownershipdataCollapsed"
      >
        <img src="/assets/images/icons/menu/anagrafiche.svg" alt="Dossier" />
        <span *ngIf="showLabels" translate>menu.ownership.main-menu</span>
      </a>
      <ul
        class="list-unstyled list-2liv"
        [ngbCollapse]="ownershipdataCollapsed"
        id="collapseOwnershipDataItem"
      >
        <li
          routerLinkActive="active"
          *canAccess="authoritiesByRoute[RoutePath.OWNERSHIP_PWC]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.OWNERSHIP +
              RoutePath.SLASH +
              RoutePath.OWNERSHIP_PWC
            "
          >
            <span *ngIf="showLabels" translate
              >menu.ownership.ownership-master</span
            >
          </a>
        </li>
        <li
          routerLinkActive="active"
          *canAccess="authoritiesByRoute[RoutePath.OWNERSHIP_TECH]"
        >
          <a
            [routerLink]="
              RoutePath.SLASH +
              RoutePath.OWNERSHIP +
              RoutePath.SLASH +
              RoutePath.OWNERSHIP_TECH
            "
          >
            <span *ngIf="showLabels" translate
              >menu.ownership.ownership-tech</span
            >
          </a>
        </li>
      </ul>
    </li>

    <li *canAccess="authoritiesByRoute.dms">
      <a [routerLink]="" (click)="navigateToDms()">
        <pwc-icon name="dms"></pwc-icon>
        <span *ngIf="showLabels" translate>menu.dms</span>
      </a>
    </li>

    <ng-container *ngIf="isDynamicFaqActive()">
      <li
        *canAccess="authoritiesByRoute[RoutePath.FAQ_LIST]"
        routerLinkActive="active"
      >
        <a
          [routerLink]="RoutePath.SLASH + RoutePath.FAQ_LIST"
          [queryParams]="faqQueryParams"
          target="_blank"
        >
          <img
            src="/assets/images/icons/menu/ask_question.svg"
            class="icon"
            alt="FAQ"
          />
          <!-- <pwc-icon name="info" style="background-color: white;"></pwc-icon> -->
          <span *ngIf="showLabels" translate>menu.faq</span>
        </a>
      </li>
    </ng-container>

    <li *ngIf="isDynamicFaqActive()" routerLinkActive="active">
      <a
        [routerLink]="
          RoutePath.SLASH +
          RoutePath.SUPPORT_REQUEST +
          RoutePath.SLASH +
          RoutePath.MY_LIST
        "
      >
        <img src="/assets/images/icons/menu/projects.svg" alt="MY_LIST" />
        <!-- <pwc-icon name="info" style="background-color: white;"></pwc-icon> -->
        <span *ngIf="showLabels" translate>menu.support-request.my-list</span>
      </a>
    </li>

    <ng-container *ngIf="isBlacklistActive()">
      <li
        *canAccess="authoritiesByRoute[RoutePath.BLACKLIST]"
        routerLinkActive="active"
      >
        <a [routerLink]="RoutePath.SLASH + RoutePath.BLACKLIST">
          <img src="/assets/images/icons/menu/projects.svg" alt="BLACKLIST" />
          <span *ngIf="showLabels" translate>menu.blacklist</span>
        </a>
      </li>
    </ng-container>

    <li
      *canAccess="authoritiesByRoute[RoutePath.INTEGRATION_DASHBOARD]"
      routerLinkActive="active"
    >
      <a [routerLink]="RoutePath.SLASH + RoutePath.INTEGRATION_DASHBOARD">
        <pwc-icon name="menu_dashboard"></pwc-icon>
        <span *ngIf="showLabels" translate>menu.integration-dashboard</span>
      </a>
    </li>

    <li
      *canAccess="authoritiesByRoute[RoutePath.UNIT_INTEGRATION_DASHBOARD]"
      routerLinkActive="active"
    >
      <a [routerLink]="RoutePath.SLASH + RoutePath.UNIT_INTEGRATION_DASHBOARD">
        <pwc-icon name="menu_dashboard"></pwc-icon>
        <span *ngIf="showLabels" translate
          >menu.unit-integration-dashboard</span
        >
      </a>
    </li>
  </main>
</div>
