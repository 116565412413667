import {Component, OnInit} from '@angular/core';
import {LogoKey} from '../../enums/logo-key.enum';
import {GeneralSettingValue, GeneralSettingValueService, SocietiesService, Society} from '@pwc-ecobonus/common';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  readonly logoLookup: Map<LogoKey, string> = new Map([
    [LogoKey.PWC, '/assets/images/logo.png'],
    [LogoKey.UNICREDIT, '/assets/images/logos/unicredit.png'],
    [LogoKey.FONDITAL, '/assets/images/logos/fondital.png']
  ]);

  helpDeskEmail: string;

  constructor(private societiesService: SocietiesService,
              private generalSettingValueService: GeneralSettingValueService) {
  }

  ngOnInit(): void {
    this.initHelpDeskEmail();
  }

  private initHelpDeskEmail(): void {
    this.societiesService.getSelectedSociety()
      .pipe(
        switchMap((society: Society) => this.generalSettingValueService.findByCode('HELPDESK_EMAIL', society.id))
      )
      .subscribe((list: GeneralSettingValue[]) => {
        if (list == null || list.length === 0) {
          this.helpDeskEmail = environment.configuration.helpDeskEmail;
          return;
        }

        const setting = list[0];
        this.helpDeskEmail = setting.value;
      });
  }

  isDynamicFAQActive(): boolean {
    return environment.configuration.dynamicFaq?.active;
  }
}
