<div class="card full-card shadow-sm my-4 card-v-center">
   <div class="card-body">
      <div class="col-6 mx-auto" style="color:black">
         <h2>Privacy Policy</h2>
         <br>
         <p>La presente informativa è resa ai sensi degli articoli 13 e 14 del Regolamento (UE) 2016/679 (il
            <b>“Regolamento”</b>
            o <b>“GDPR”</b>) a coloro che accedono alla piattaforma raggiungibile all’indirizzo web
            [ecobonus.e001.digitalsuite.pwc-tls.it] (di seguito, la <b>“Piattaforma”</b>), nonché ai soggetti i cui dati
            personali saranno trasmessi tramite la stessa.
         </p>
         <p class="paragraph">
            <b>1. PREMESSA: COS’E’ LA PIATTAFORMA?</b>
         </p>
         <p>
            PricewaterhouseCoopers Services S.r.l è titolare della Piattaforma e ha concesso a TLS Associazione
            Professionale di
            Avvocati e Commercialisti (di seguito, per brevità <b>“PwC TLS”</b>) il diritto d’uso della stessa.
            Per agevolare l’invio delle informazioni e dei documenti necessari all’esecuzione dei servizi che PwC TLS,
            come sopra
            definita, si è impegnata a svolgere nei confronti dei propri clienti relativamente agli interventi di
            efficientamento
            energetico e di riduzione del rischio sismico di cui al d.l. 19 maggio 2020, n. 34, recante “Misure urgenti
            in materia
            di salute, sostegno al lavoro e all'economia, nonché di politiche sociali connesse all'emergenza
            epidemiologica da
            COVID-19”, convertito con modificazioni dalla L. 17 luglio 2020, n. 77 (di seguito, <b>“Decreto
               Rilancio”</b>), è resa
            disponibile agli utenti la presente Piattaforma, dove saranno caricati i documenti e le informazioni
            relative ai
            beneficiari dell’agevolazione fiscale, ai referenti di progetto, ai fornitori dei beni e servizi agevolabili
            fiscalmente, agli amministratori di condominio e/o ai condomini (complessivamente, gli
            <b>“Interessati”</b>).
         </p>
         <p class="paragraph">
            <b>2. CHI SONO I CONTITOLARI DEL TRATTAMENTO?</b>
         </p>
         <p>
            <b>PwC TLS</b>, con sede legale in Piazza Tre Torri, n. 2 – 20145, Milano e <b>Servizi Aziendali
               PricewaterhouseCoopers S.r.l.</b>, con sede legale in Piazza Tre Torri, n. 2 – 20145, Milano in persona
            dei propri
            legali rappresentanti pro tempore sono i contitolari del trattamento (i <b>“Contitolari”</b>).
            <br>
            Ai sensi dell’articolo 26 del Regolamento, i Contitolari hanno sottoscritto un accordo di contitolarità il
            cui
            contenuto essenziale è a disposizione presso le sedi dei Contitolari.
            <br>
            Inoltre, i Contitolari hanno nominato un Responsabile della protezione dei dati (<b>“RPD”</b>), che Lei
            potrà
            contattare ai seguenti recapiti:
         </p>
         <ul>
            <li>Ufficio del Responsabile della Protezione dei Dati / Data Protection Officer, Piazza Tre Torri, n. 2 –
               20145,
               Milano;</li>
            <li>Indirizzo PEC: dpo-tls&#64;pec-pwc.it</li>
            <li>Tel. (02) 91605650; Fax (02) 91606561/
            </li>
         </ul>

         <p class="paragraph">
            <b>3. COS’É IL DATO PERSONALE? </b>
         </p>
         <p>
            “<b>Dato Personale</b>” significa qualsiasi informazione idonea a identificare, direttamente o
            indirettamente, una
            persona fisica.
            In particolare, i Contitolari trattano le seguenti categorie di dati personali relative agli Interessati (i
            “<b>Dati</b>”):
         </p>
         <ul>
            <li>l’indirizzo IP, il nome a dominio e l’URL del dispositivo utilizzato;</li>
            <li>i dati e la cronologia di navigazione;</li>
            <li>nome e cognome;</li>
            <li>dati di contatto e i dati utilizzati per la registrazione alla Piattaforma (ad esempio, l’indirizzo
               <em>e-mail</em>);
            </li>
            <li>dati contenuti nei documenti caricati sulla Piattaforma;</li>
            <li>dati relativi immobile all’immobile interessato dagli interventi edilizi agevolabili;</li>
            <li>dati relativi al progetto edilizio per il quale si richiede l’agevolazione fiscale;</li>
            <li>eventuali altri dati da Lei forniti volontariamente. </li>
         </ul>
         <p>
            Nell’accedere alla Piattaforma e fornire ai Contitolari le informazioni necessarie per eseguire i servizi
            convenuti
            con i clienti di PwC TLS, Lei potrebbe comunicare anche dati personali relativi a terzi Interessati, ad
            esempio
            completando le relative anagrafiche e caricando la documentazione necessaria per adempiere all’ incarico.
            Procedendo
            con la trasmissione di tali Dati, Lei conferma che tali Interessati siano stati debitamente informati ai
            sensi del
            Regolamento.
         </p>

         <p class="paragraph">
            <b>4. QUALI SONO LE FINALITÀ DEL TRATTAMENTO DEI SUOI DATI E LA RELATIVA BASE GIURIDICA?</b>
         </p>
         <p>
            <b>4.1</b> I Contitolari raccolgono e trattano i Dati per l’espletamento dell’incarico.
         </p>
         <p>
            Pertanto, il trattamento dei dati personali sarà effettuato:
         </p>
         <ul>
            <li>se Lei ha sottoscritto il contratto, per eseguire il contratto di cui Lei è parte, nel rispetto
               dell’articolo 6,
               par. 1, lett. b), GDPR o;</li>
            <li>se Lei non è una parte del contratto, sulla base del legittimo interesse dei Contitolari, nel rispetto
               dell’articolo 6, par. 1, lett. f), GDPR.</li>
         </ul>

         <p>
            <b>4.2</b> I Contitolari tratteranno altresì i Dati per adempiere gli obblighi previsti da leggi e
            regolamenti
            nazionali e comunitari (es. normativa antiriciclaggio e antiterrorismo) o, per quanto applicabili, da
            normative
            vigenti presso paesi terzi, nonché per eseguire un ordine di Autorità giudiziarie, enti o organismi al cui
            potere di
            vigilanza sono soggetti i Contitolari, nel rispetto dell’articolo 6, par. 1, lett. c), GDPR.
         </p>
         <p>
            <b>4.3</b> I Dati potranno altresì essere oggetto di trattamento per eseguire le disposizioni delle
            procedure del
            network PwC riguardanti processi e aspetti organizzativi, gestionali e operativi inerenti il conferimento e
            l’esecuzione degli incarichi e i rapporti con la clientela (es. verifiche di indipendenza e di potenziali
            conflitti
            di interesse, procedure di gestione del rischio e di controllo della qualità), nel rispetto dell’articolo 6,
            par. 1,
            lett. f), GDPR.
         </p>
         <p>
            <b>4.4</b> I Contitolari tratteranno, infine, i Dati per esercitare i propri diritti e, in particolare, il
            diritto di
            difesa in giudizio, nel rispetto dell’articolo 6, par. 1, lett. f), GDPR.
         </p>

         <p>
            Il conferimento dei Dati per le finalità sopra indicate è obbligatorio e non richiede il consenso degli
            Interessati.
            Un eventuale rifiuto di fornire tali dati e/o l’opposizione al loro trattamento comporterebbe
            l'impossibilità per PwC
            TLS di eseguire l’espletamento dell’incarico.
         </p>

         <p class="paragraph">
            <b>5. CHI HA ACCESSO AI SUOI DATI?</b>
         </p>
         <p>
            I Dati potranno essere resi accessibili a:
         </p>
         <ul>
            <li>i) dipendenti e collaboratori dei Contitolari, nella loro qualità di addetti autorizzati al trattamento
               dei Dati
               (o c.d. “Incaricati al trattamento”);</li>
            <li>ii) terzi soggetti che svolgono attività in outsourcing per conto dei Contitolari, anche ai fini della
               conservazione dei Dati, nella loro qualità di responsabili del trattamento;</li>
            <li>iii) Autorità giudiziarie o di vigilanza, amministrazioni, enti ed organismi pubblici (nazionali ed
               esteri);</li>
            <li>iv) altre entità giuridiche del network PwC nazionale ed internazionale di cui i Contitolari fanno
               parte. </li>
         </ul>
         <p>
            L’elenco aggiornato dei responsabili e degli Incaricati del trattamento è custodito presso le sedi dei
            Contitolari.
         </p>

         <p> class="paragraph"
            <b>6. TRASFERIAMO I DATI ALL’ESTERO?</b>
         </p>
         <p>
            Poiché i Contitolari operano nell’ambito di un network composto da entità giuridiche indipendenti aventi
            sede in
            diversi paesi del mondo, i Dati potrebbero essere trasferiti e conservati anche fuori dall’Unione europea,
            inclusi i
            paesi che non garantiscono un livello di protezione adeguato. In ogni caso, tali trasferimenti avverranno
            sempre e
            comunque nel rispetto delle condizioni previste dagli articoli 45 e 46, GDPR.
         </p>
         <p>
            La gestione e la conservazione dei Dati avvengono in cloud e su server ubicati all’interno dell’Unione
            europea di
            proprietà e/o nella disponibilità dei Contitolari e/o di società terze incaricate e debitamente nominate
            quali
            responsabili del trattamento. Il trasferimento all’estero dei Dati nei paesi extra-UE avviene in conformità
            alle
            disposizioni contenute nel Capo V, GDPR (articolo 46), mediante l’adozione di clausole-tipo redatte sulla
            base delle
            versioni n. 2004/915/EC e n. 2010/87/EU elaborate dalla Commissione europea.
         </p>
         <p>
            I Dati non saranno soggetti a diffusione.
         </p>

         <p class="paragraph">
            <b>7. PER QUANTO TEMPO CONSERVIAMO I SUOI DATI?</b>
         </p>
         <p>
            I Dati saranno conservati per i termini prescrizionali applicabili ex lege, maggiorati di dodici mesi, anche
            ai fini
            dell’eventuale necessità di accertamento, esercizio o difesa dei diritti dei Contitolari derivanti dalla
            necessità di
            comprovare la regolare esecuzione della prestazione professionale.
         </p>
         <p>
            Ci riserviamo il diritto di conservare i dati c.d. di log per periodi superiori, al fine di poter gestire
            eventuali
            reati commessi ai danni della Piattaforma (e.g., hacking).
         </p>

         <p class="paragraph">
            <b>8. QUALI SONO I SUOI DIRITTI IN QUALITÀ DI INTERESSATO DEL TRATTAMENTO?</b>
         </p>
         <p>
            In conformità a quanto previsto nel Capo III, Sezione I, GDPR, l’Interessato può esercitare i diritti ivi
            contenuti
            ed in particolare:
         </p>
         <ul>
            <li><b>Diritto di accesso</b> - Ottenere conferma che sia o meno in corso un trattamento di Dati riguardanti
               l’Interessato e, in tal caso, ricevere informazioni relativamente a, tra le altre: finalità del
               trattamento,
               categorie di Dati trattati e periodo di conservazione, destinatari cui questi possono essere comunicati
               (articolo
               15, GDPR);</li>
            <li><b>Diritto di rettifica</b> - Ottenere, senza ingiustificato ritardo, la rettifica dei Dati personali
               inesatti
               che riguardano l’Interessato e l’integrazione dei dati personali incompleti (articolo 16, GDPR);</li>
            <li><b>Diritto alla cancellazione</b> - Ottenere, senza ingiustificato ritardo, la cancellazione dei Dati
               riguardanti
               l’Interessato, nei casi previsti dal GPDR (articolo 17, GDPR);</li>
            <li><b>Diritto di limitazione</b> - Ottenere dai Contitolari la limitazione del trattamento, nei casi
               previsti dal
               GDPR (articolo 18 GDPR);</li>
            <li><b>Diritto alla portabilità</b> - Ricevere in un formato strutturato, di uso comune e leggibile da un
               dispositivo
               automatico, i Dati forniti ai Contitolari, e ottenere che gli stessi siano trasmessi ad altro titolare
               senza
               impedimenti, nei casi previsti dal GDPR (articolo 20 GDPR);</li>
            <li><b>Diritto di opposizione</b> - Opporsi al trattamento dei dati riguardanti l’Interessato, salvo che
               sussistano
               motivi legittimi per i Contitolari di continuare il trattamento (articolo 21 GDPR);</li>
            <li><b>Diritto di proporre reclamo all’autorità di controllo</b> - Proporre reclamo all’Autorità Garante per
               la
               protezione dei dati personali, Piazza di Montecitorio n. 121, 00186, Roma (RM).</li>
         </ul>

         <p>
            L’Interessato può inviare una richiesta di esercizio di tali diritti mediante comunicazione da trasmettere
            all’ufficio del RPD tramite l’indirizzo PEC indicato in precedenza.
         </p>

         <p class="paragraph">
            <b>9. CON QUALI MODALITA’ TRATTIAMO I DATI?</b>
         </p>
         <p>
            Il trattamento dei Dati da parte dei Contitolari è realizzato per mezzo delle operazioni indicate
            all’articolo 4, n.
            2, GDPR, compiute con o senza l’ausilio di sistemi informatici e precisamente: raccolta, registrazione,
            organizzazione, strutturazione, aggiornamento, conservazione, adattamento o modifica, estrazione ed analisi,
            consultazione, uso, comunicazione mediante trasmissione, raffronto, interconnessione, limitazione,
            cancellazione o
            distruzione dei Dati.
         </p>
         <p>
            I Contitolari si obbligano, sin da ora, a mantenere riservati i Dati e le informazioni ricevuti e ad
            adottare le
            misure atte a garantire un’adeguata tutela degli stessi, assicurando la necessaria confidenzialità e
            riservatezza
            circa il loro contenuto.
         </p>
         <p>
            In conformità a quanto previsto dall’articolo 32, GDPR, tenuto conto della natura, dell’oggetto, del
            contesto e delle
            finalità del trattamento, i Contitolari dichiarano di avere messo in atto misure tecniche ed organizzative
            adeguate,
            anche con eventuale riferimento alle particolari categorie di Dati di cui agli articoli 9 e 10, GDPR, per
            garantire
            un livello di sicurezza idoneo al rischio, che comprendono, in via esemplificativa e non esaustiva: (i) la
            pseudonimizzazione e la cifratura dei Dati; (ii) la capacità di assicurare su base permanente la
            riservatezza,
            l’integrità, la disponibilità e la resilienza dei sistemi e dei servizi di trattamento; (iii) la capacità di
            ripristinare tempestivamente la disponibilità e l’accesso dei Dati in caso di incidente fisico o tecnico;
            (iv) una
            procedura per testare, verificare e valutare regolarmente l’efficacia delle misure tecniche e organizzative
            al fine
            di garantire la sicurezza del trattamento. I Contitolari saranno responsabili della protezione del proprio
            sistema
            informatico.
         </p>

         <p class="paragraph">
            <b>10 DISPOSIZIONI FINALI</b>
         </p>
         <p>
            I Contitolari si riservano il diritto di modificare e/o aggiornare la presente Privacy Policy.
         </p>

         <div class="mt-4 mb-4 row d-flex flex-row justify-content-center">

            <a (click)="back()" class="btn btn-primary btn-rounded text-nowrap pointer"
               style="width: 150px">
               <span class="d-inline-block" translate>
                  actions.back
               </span>
            </a>
         </div>
      </div>
   </div>
</div>
