import { ChatbotInteractionRateEnum } from '../enums/chatbot-interaction-rate.enum';

/**
 * ChatBot model for rating the conversation
 */
export class ChatbotUserRate {

  // tslint:disable-next-line:variable-name
  public _like: boolean = false;
  // tslint:disable-next-line:variable-name
  public _dislike: boolean = false;
  // tslint:disable-next-line:variable-name
  public readonly _sessionId: string;
  // tslint:disable-next-line:variable-name
  public _messageId: string;
  public canVoteBeChanged: boolean = true;

  constructor(sessionId: string) {
    this._sessionId = sessionId;
  }

  public set like(v: boolean) {
    if (this.canVoteBeChanged) {
      this._like = v;
    }
  }

  public get like(): boolean {
    return this._like;
  }

  public set dislike(v: boolean) {
    if (this.canVoteBeChanged) {
      this._dislike = v;
    }
  }

  public get dislike(): boolean {
    return this._dislike;
  }

  public get sessionId(): string {
    return this._sessionId;
  }

  public set messageId(id: string) {
    this._messageId = id;
  }

  public get messageId(): string {
    return this._messageId;
  }

  public get interactionId(): string {
    return this._sessionId + '-' + this._messageId;
  }

  public resetFlags(): void {
    this._like = false;
    this._dislike = false;
    this.canVoteBeChanged = true;
  }

  public getRealRateValue(): ChatbotInteractionRateEnum {
    if (!this._like && !this._dislike) {
      return ChatbotInteractionRateEnum.NONE;
    }
    if (this._like) {
      return ChatbotInteractionRateEnum.LIKE;
    }
    if (this._dislike) {
      return ChatbotInteractionRateEnum.DISLIKE;
    }
  }
}
