import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StarredSocietyService {

  protected baseUrl: string = environment.apiUrl;
  protected endpoint: string = 'starred_society';

  constructor(private http: HttpClient) {
  }

  public starSociety(societyId: number){
    return this.http.post(`${this.baseUrl}/${this.endpoint}/save/${societyId}`, null);
  }

  public unstarSociety(societyId: number) {
    return this.http.post(`${this.baseUrl}/${this.endpoint}/remove/${societyId}`, null);
  }

  public getStarredSociety(){
    return this.http.get(`${this.baseUrl}/${this.endpoint}/retrieve`);
  }
}
