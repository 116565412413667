import { Component, ViewEncapsulation } from '@angular/core';

/**
 * This component is used to display a typing indicator.
 * It is used by the chatbot component.
 *
 * @author Mc Ayrton Aragon
 */
@Component({
  selector: 'app-chatbot-typing-indicator',
  templateUrl: './chatbot-typing-indicator.component.html',
  styleUrls: ['./chatbot-typing-indicator.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ChatbotTypingIndicatorComponent {

  constructor() {
  }

}
