import { Injectable } from '@angular/core';
import { User, UserService } from '@pwc-ecobonus/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPrivacyServiceService extends UserService{

  public accepPrivacy(): Observable<User>{
    return this.http.post<User>(`${this.baseUrl}/${this.endpoint}/accept-privacy`,{});
  }
}
