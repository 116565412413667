import { AsyncCountdownTimerStatusEnum } from './async-countdown-timer-status.enum';

/**
 * This is a countdown timer component.
 * Here you can find the countdown timer logic.
 * It helps to count down the time and execute something when the countdown finishes.
 *
 * @author Mc Ayrton Aragon
 */
export class AsyncCountdownTimer {
  private timerId: NodeJS.Timeout | null = null;
  public readonly countDownInSeconds: number;
  private readonly onCountdownFinish: () => void;
  private status: AsyncCountdownTimerStatusEnum = AsyncCountdownTimerStatusEnum.STOPPED;
  private readonly timerDescription: string | null;

  constructor(
    countDownInSeconds: number,
    onCountdownFinish: () => void,
    timerDescription: string | null = null,
  ) {
    this.countDownInSeconds = countDownInSeconds;
    this.onCountdownFinish = onCountdownFinish;
    this.timerDescription = timerDescription;
  }

  /**
   * Starts the countdown timer.
   */
  public start(): void {
    this.timerId = setTimeout(this.onCountdownFinish, this.countDownInSeconds * 1000);
    this.status = AsyncCountdownTimerStatusEnum.STARTED;
  }

  /**
   * Stops the countdown timer.
   */
  public stop(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
      this.status = AsyncCountdownTimerStatusEnum.STOPPED;
    }
  }

  /**
   * Resets the countdown timer.
   */
  public reset(): void {
    this.stop();
    this.start();
  }

  /**
   * Gets the timer status.
   */
  public getTimerStatus(): AsyncCountdownTimerStatusEnum {
    return this.status;
  }

  /**
   * Gets the timer description.
   */
  public getTimerDescription(): string | null {
    return this.timerDescription;
  }

}
