import { environment } from '../../../../environments/environment';
import { OpenAIChatbotMockupModeEnum } from '../enums/open-a-i-chatbot-mockup-mode.enum';
import { ChatbotUserRate } from './chatbot-user-rate';

/**
 * Chatbot message model.
 */
export class ChatbotMessage {
  /**
   * True if the message is from the user, false if is from the bot.
   */
  isUserMessage: boolean;

  /**
   * The message text.
   */
  text: string;

  /**
   * True if the bot is thinking, false otherwise.
   */
  isBotThinking: boolean;

  /**
   * True if the message is a closing message, false otherwise.
   */
  isClosingMessage: boolean;

  /**
   * True if the rating section is enabled, false otherwise.
   */
  isRatingEnabled: boolean;

  /**
   * The link to report a wrong response.
   */
  isLinkToReportMessage?: string;

  /**
   * Bank environment selected
   */
  chatbotMockupMode?: OpenAIChatbotMockupModeEnum;

  /**
   * True if the button to report a wrong response is enabled, false otherwise.
   */
  isLinkToReportEnabled?: boolean;

  /**
   * The link url, eg. terms and conditions link, if present.
   */
  linkUrl?: string;

  /**
   * The text of the link in final position.
   */
  linkText: string;

  /**
   * The user rate.
   */
  chatbotUserRate?: ChatbotUserRate;

  /**
   * Constructor.
   * @param isUserMessage true if the message is from the user, false if is from the bot
   * @param text the message text
   * @param isBotThinking true if the bot is thinking, false otherwise
   * @param isClosingMessage true if the message is a closing message, false otherwise
   * @param isRatingEnabled true if the rating section is enabled, false otherwise
   * @param chatbotMockupMode the bank environment selected
   * @param reportText the link to report a wrong response
   * @param isLinkToReportEnabled true if the button to report a wrong response is enabled, false otherwise
   * @param termsAndCondition the terms and conditions link, if present
   * @param chatbotUserRate the user rate
   */
  constructor(
    isUserMessage: boolean,
    text: string,
    isBotThinking: boolean = false,
    isClosingMessage: boolean,
    isRatingEnabled: boolean,
    chatbotMockupMode?: OpenAIChatbotMockupModeEnum,
    reportText?: string,
    isLinkToReportEnabled?: boolean,
    chatbotUserRate?: ChatbotUserRate,
    linkUrl?: string,
    linkText: string = "link"
  ) {
    this.isUserMessage = isUserMessage;
    this.text = text;
    this.isBotThinking = isBotThinking;
    this.isClosingMessage = isClosingMessage;
    this.isRatingEnabled = isRatingEnabled;
    this.chatbotMockupMode = chatbotMockupMode;
    if (!isUserMessage && isLinkToReportEnabled) {
      let firstParam: string = 'entry.603384650=';
      let secondParam: string = '&entry.872826190=';
      this.isLinkToReportMessage = encodeURI(
        environment.configuration.chatbot.chatbotReportLink +
          firstParam +
          chatbotMockupMode +
          secondParam +
          reportText
      );
    } else {
      this.isLinkToReportMessage = null;
    }
    this.isLinkToReportEnabled = isLinkToReportEnabled;
    this.chatbotUserRate = chatbotUserRate;
    this.linkUrl = linkUrl;
    this.linkText = linkText;
  }
}
