<pwc-layout [logoMap]="logoLookup">
  <ng-container app-nav-menu>
    <app-nav-menu></app-nav-menu>
  </ng-container>

  <ng-container app-header>
    <app-header></app-header>
  </ng-container>
  

  <ng-container app-footer-right>
    <app-chatbot></app-chatbot>
  </ng-container>

  <ng-container app-footer-right *ngIf="helpDeskEmail && !isDynamicFAQActive()">
    <span translate>help-desk.help-text</span>
    &nbsp;
    <a class="color-orange" [href]="'mailto:' + helpDeskEmail" translate>help-desk.help-link</a>
  </ng-container>
  
</pwc-layout>
