import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

import { ChatbotMessage } from './models/chatbot-message';
import { ChatbotService } from './services/chatbot.service';
import { OpenAIChatbotModelEnum } from './enums/open-a-i-chatbot-model.enum';
import { Authority, AuthorizationService } from '@pwc-ecobonus/security';

import { AuthorityCodeEnum } from 'src/app/enums/security/authority-code.enum';
import { TranslateService } from '@ngx-translate/core';
import { OpenAIChatbotLanguageSupportEnum } from './enums/open-a-i-chatbot-language-support.enum';
import { AsyncCountdownTimer } from './components/countdown-timer/async-countdown-timer';
import { AsyncCountdownTimerStatusEnum } from './components/countdown-timer/async-countdown-timer-status.enum';
import { environment } from 'src/environments/environment';
import { ChatbotUserRate } from './models/chatbot-user-rate';
import { take, takeUntil, timeout } from 'rxjs/operators';
import { Subject, Subscription, timer } from 'rxjs';
import { OpenAIChatbotMockupModeEnum } from './enums/open-a-i-chatbot-mockup-mode.enum';
import { ChatbotHealthCheckEnum } from './enums/chatbot-health-check.enum';
import { ChatbotSettings } from './models/chatbot-settings';
import { ChatbotSettingsEnum } from './enums/chatbot-settings.enum';
import { ChatbotResponse } from './models/chatbot-response';
import { SocietiesService, Society } from '@pwc-ecobonus/common';
import { ChatbotRda } from './models/chatbot-rda';
import { ChatbotRdaMessage } from './models/chatbot-rda-message';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
    ]),
    trigger('translateInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms', style({ transform: 'translateX(-100%)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ transform: 'translateX(0)' })),
      ]),
    ])
  ],
})

export class ChatbotComponent implements OnInit, OnDestroy {
  public isToggleChatSectionButtonShowAble: boolean = false;
  public isChatbotShowAble: boolean = false;
  public isChatSectionShowed: boolean = false;
  public messages: ChatbotMessage[] = [];
  public isMessageAnswered: boolean = false;
  public chatMode: OpenAIChatbotModelEnum = OpenAIChatbotModelEnum.OPENAI;
  public chatbotTitle: string;
  public chatbotSecondaryTitle: string;
  public tooltipOpeningChatbotPlaceholder: string;
  public tooltipClosingChatbotPlaceholder: string;
  public tooltipChatbotEnvironmentPlaceholder: string;
  public chatbotMockupModes = Object.values(OpenAIChatbotMockupModeEnum);
  public isChatbotMockupModeEnabled = environment.configuration.chatbot.mockupModeEnabled;
  public chatbotMockupMode: OpenAIChatbotMockupModeEnum = environment.configuration.chatbot.mockupModeEnabled ?
    OpenAIChatbotMockupModeEnum.ALL : null;
  public isChatbotOnline: boolean = true;
  public chatbotOfflinePlaceholder: string;
  private rdaMessageClosedTime: string;
  private rdaMessageOk: string;

  private readonly chatbotTimeoutInSeconds: number = 300;
  private readonly chatbotErrorTimeoutInSeconds: number = 30;
  private readonly chatbotHealthCheckTimeoutInSeconds: number = 90;
  private readonly chatbotHealthCheckErrorTimeoutInSeconds: number = 8;

  private retryChatbotAlreadyDoneOnce: boolean = false;
  private readonly maxRetries: number = environment.configuration.chatbot.maxRetries;
  private chatbotPollingSubscription: Subscription;
  private readonly sessionId: string;
  private messageId: number = 1;
  private chatbotUserRate: ChatbotUserRate;
  private authorities: AuthorityCodeEnum[] = [];
  private isChatAlreadyStarted: boolean = false;
  private currentLanguage: OpenAIChatbotLanguageSupportEnum =
    OpenAIChatbotLanguageSupportEnum.IT;
  private countdownTimer: AsyncCountdownTimer;
  private chatbotEnabled: boolean = environment.configuration.chatbot.enabled;
  private healthCheckPolling: boolean = environment.configuration.chatbot.healthCheck.enabled;
  private chatbotAIModeEnabled: boolean =
    environment.configuration.chatbot.canChangeModeGlobally;
  private linkToReport: boolean =
    environment.configuration.chatbot.chatbotLinkToReportEnabled;
  private rdaMaxMessages: number = environment.configuration.chatbot.rdaMaxMessages;

  private society: Society = null;

  constructor(
    public router: Router,
    private chatbotService: ChatbotService,
    private authorizationService: AuthorizationService,
    private societiesService: SocietiesService,
    private translateService: TranslateService,
  ) {
    // create an uuid for the session id
    this.sessionId = this.generateSessionId();
    this.chatbotUserRate = new ChatbotUserRate(this.sessionId);
    this.chatbotUserRate.messageId = this.generateMessageId();
    this.currentLanguage = this.getChatbotLanguageToUse(
      this.translateService.getBrowserLang()
    );
    this.chatbotTitle = this.translateService.instant(
      'chatbot.description.header-title');
    this.chatbotSecondaryTitle = this.translateService.instant(
      'chatbot.description.header-secondary-title');
    this.tooltipOpeningChatbotPlaceholder = this.translateService.instant(
      'chatbot.description.opening-chatbot-button');
    this.tooltipClosingChatbotPlaceholder = this.translateService.instant(
      'chatbot.description.closing-chatbot-button');
    this.tooltipChatbotEnvironmentPlaceholder = this.translateService.instant(
      'chatbot.description.tooltipChatbotEnvironmentPlaceholder');
    this.chatbotOfflinePlaceholder = this.translateService.instant(
      'chatbot.description.offline-chatbot-overlay');
    this.rdaMessageClosedTime = this.translateService.instant(
      'chatbot.description.rda-closed');
    this.rdaMessageOk = this.translateService.instant(
      'chatbot.description.rda-ok');
  }

  ngOnInit(): void {
    this.applyChatbotSettings();

    // Subscribe to society updates
    this.societiesService.getSelectedSociety().subscribe(
      (society: Society) => {
        this.society = society;
      })
  }

  ngOnDestroy() {
    this.stopHealthCheckPolling();
  }

  stopHealthCheckPolling(): void {
    if (this.chatbotPollingSubscription) {
      this.chatbotPollingSubscription.unsubscribe();
    }
  }

  /**
   * Generate a random UIID
   * @returns UUID
   */
  private generateSessionId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      // tslint:disable-next-line:only-arrow-functions
      function (c: string) {
        // tslint:disable-next-line:no-bitwise one-variable-per-declaration
        const r = (Math.random() * 16) | 0,
          // tslint:disable-next-line:no-bitwise
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  /**
   * Generate a random UIID with message tracing
   * @returns UUID
   */
  private generateMessageId(): string {
    let tempMessageId = String(this.messageId++);
    while (tempMessageId.length < 4) {
      tempMessageId = '0' + tempMessageId;
    }
    return tempMessageId;
  }

  /**
   * Executes all chatbot logic.
   */
  public executeChatbot(): void {
    if (this.isChatbotShowAble) {
      // If healthcheck is enabled, check if chatbot is available every 60 seconds.
      // If the service answers DISABLED, disable the polling.
      if (this.healthCheckPolling) {
        this.chatbotPollingSubscription = timer(0, this.chatbotHealthCheckTimeoutInSeconds * 1000)
          .subscribe(
            () => {
              this.checkHealth();
            }
          );
      }

      if (!this.isChatAlreadyStarted) {
        this.isChatAlreadyStarted = true;
        const link: string = environment.configuration.chatbot.chatbotTos;
        this.messages.push(
          new ChatbotMessage(
            false,
            this.translateService.instant(
              'chatbot.description.condition-accept-message'
            ),
            false, false, false, null, '', false, null, link)
        );
        this.getGreetingMessage();
      }
      this.countdownTimer = new AsyncCountdownTimer(
        this.chatbotTimeoutInSeconds,
        () => {
          this.getClosingMessage();
        },
        'Chatbot Ending Message Timeout'
      );
    }
  }

  private checkHealth(): void {
    this.chatbotService.healthCheck().subscribe(
      (response: ChatbotHealthCheckEnum) => {
        switch (response) {
          case ChatbotHealthCheckEnum.OK:
            this.retryChatbotAlreadyDoneOnce = false;
            this.isChatbotOnline = true;
            break;
          case ChatbotHealthCheckEnum.DISABLED:
            this.isChatbotOnline = true;
            this.healthCheckPolling = false;
            this.stopHealthCheckPolling();
            break;
          case ChatbotHealthCheckEnum.ERROR:
            this.isChatbotOnline = false;
            if (!this.retryChatbotAlreadyDoneOnce) {
              this.onCheckHealthError();
            }
            break;
        }
      },
      () => {
        this.isChatbotOnline = false;
      }
    );
  }

  private onCheckHealthError(): void {
    const stopRetry = new Subject<void>();

    timer(
      this.chatbotHealthCheckErrorTimeoutInSeconds * 1000,
      this.chatbotHealthCheckErrorTimeoutInSeconds * 1000)
      .pipe(
        take(this.maxRetries),
        takeUntil(stopRetry)
      )
      .subscribe(
        (retryCounter) => {
          this.chatbotService.healthCheck()
            .subscribe(
              (retryResponse: ChatbotHealthCheckEnum) => {
                if (retryResponse === ChatbotHealthCheckEnum.ERROR) {
                  if (retryCounter + 1 >= this.maxRetries) {
                    this.retryChatbotAlreadyDoneOnce = true;
                    stopRetry.next();
                  }
                } else {
                  switch (retryResponse) {
                    case ChatbotHealthCheckEnum.OK:
                      this.isChatbotOnline = true;
                      break;
                    case ChatbotHealthCheckEnum.DISABLED:
                      this.isChatbotOnline = true;
                      this.healthCheckPolling = false;
                      this.stopHealthCheckPolling();
                      break;
                  }
                  stopRetry.next();
                }
              }
            );
        });
  }

  /**
   * Obtains a different greeting message.
   */
  private getGreetingMessage(): void {
    this.messages.push(
      new ChatbotMessage(
        false,
        'Ciao, sono il tuo assistente virtuale. Come posso aiutarti?',
        true,
        false,
        false,
        null,
        '',
        false
      )
    );
    const openingMessageOnDestroy$ = timer((this.chatbotErrorTimeoutInSeconds + 5) * 1000);
    this.chatbotService.getGreetingMessage()
      .pipe(
        timeout(this.chatbotErrorTimeoutInSeconds * 1000),
        takeUntil(openingMessageOnDestroy$))
      .subscribe(
        (response) => {
          this.messages.pop();
          this.messages.push(
            new ChatbotMessage(false, response, false, false, false, null, '', false)
          );
          this.isMessageAnswered = true;
        },
        () => {
          this.messages.pop();
          this.messages.push(
            new ChatbotMessage(
              false,
              this.translateService.instant(
                'chatbot.description.error-random-message-frontend'
              ),
              false,
              false,
              false,
              null,
              '',
              false
            )
          );
          this.isMessageAnswered = true;
        }
      );
  }

  /**
   * Obtains a different closing message.
   */
  private getClosingMessage(): void {
    this.messages.push(
      new ChatbotMessage(
        false,
        'Posso fare altro per te?',
        true,
        false,
        false,
        null,
        '',
        false
      )
    );
    const closingMessageOnDestroy$ = timer((this.chatbotErrorTimeoutInSeconds + 5) * 1000);
    this.chatbotService.getClosingMessage()
      .pipe(
        timeout(this.chatbotErrorTimeoutInSeconds * 1000),
        takeUntil(closingMessageOnDestroy$))
      .subscribe(
        (response) => {
          this.messages.pop();
          this.messages.push(
            new ChatbotMessage(false, response, false, true, false, null, '', false)
          );
          this.isMessageAnswered = true;
        },
        () => {
          this.messages.pop();
          this.messages.push(
            new ChatbotMessage(
              false,
              this.translateService.instant(
                'chatbot.description.error-random-message-frontend'
              ),
              false,
              false,
              false,
              null,
              '',
              false
            )
          );
          this.isMessageAnswered = true;
        }
      );
  }

  /**
   * Obtains the user authorizations.
   */
  private applyChatbotSettings(): void {
    this.chatbotService
      .getSettings()
      .subscribe((response: ChatbotSettings) => {
        if (this.chatbotEnabled && (response && response !== undefined && response.chatbotStatus === ChatbotSettingsEnum.ENABLED)) {
          this.isChatbotShowAble = true;
        }
        if (this.chatbotAIModeEnabled && (response && response !== undefined && response.switchModel === ChatbotSettingsEnum.ENABLED)) {
          this.isToggleChatSectionButtonShowAble = true;
        }
        if (this.healthCheckPolling && (response && response !== undefined)) {
          this.healthCheckPolling = response.healthCheckStatus === ChatbotSettingsEnum.ENABLED;
        }

        this.executeChatbot();
      });
  }

  /**
   * Toggles the chatbot visibility.
   */
  public toggleChatSection(): void {
    this.isChatSectionShowed = !this.isChatSectionShowed;
  }

  /**
   * Sends a message to the chatbot.
   * @param message the message to send.
   */
  public sendMessage(message: string): void {
    if (this.isMessageAnswered) {
      this.messages.push(
        new ChatbotMessage(true, message, false, false, false, null, '', false)
      );
      this.chatbotUserRate.messageId = this.generateMessageId();
      this.isMessageAnswered = false;
      this.messages.push(
        new ChatbotMessage(false, 'sta scrivendo...', true, false, false, null, '', false)
      );
      const sendMessageOnDestroy$ = timer((this.chatbotErrorTimeoutInSeconds + 5) * 1000);
      this.chatbotService
        .post(
          this.chatbotUserRate.sessionId,
          this.chatbotUserRate.messageId,
          message,
          this.chatMode,
          this.currentLanguage,
          this.chatbotMockupMode
        ).pipe(
          timeout(this.chatbotErrorTimeoutInSeconds * 1000),
          takeUntil(sendMessageOnDestroy$))
        .subscribe(
          (result) => {
            this.messages.pop();
            this.messages.push(
              new ChatbotMessage(
                false,
                result.response,
                false,
                false,
                true,
                this.chatbotMockupMode,
                message,
                this.linkToReport,
                this.chatbotUserRate
              )
            );

            if (result.rdaRequired) {
              try {
                this.onRdaRequired(message, result);
              } catch (e) {
                console.error("Failed to save chatbot RDA information");
              }
            }

            this.isMessageAnswered = true;
            this.chatbotUserRate = new ChatbotUserRate(this.sessionId);

            this.chatbotUserRate.resetFlags();
            this.manageTimer(this.countdownTimer);
          },
          () => {
            this.messages.pop();
            this.chatbotUserRate.resetFlags();
            this.messages.push(
              new ChatbotMessage(
                false,
                this.translateService.instant(
                  'chatbot.description.error-processed-message-frontend'
                ),
                false,
                false,
                false,
                this.chatbotMockupMode,
                message,
                false
              )
            );
            this.isMessageAnswered = true;
          }
        );
    }
  }

  /**
   * This method manages the resets of the timer or the start of it.
   * @param countdownTimer the timer to manage.
   */
  private manageTimer(countdownTimer: AsyncCountdownTimer): void {
    if (
      countdownTimer.getTimerStatus() === AsyncCountdownTimerStatusEnum.STARTED
    ) {
      countdownTimer.reset();
    } else if (
      countdownTimer.getTimerStatus() === AsyncCountdownTimerStatusEnum.STOPPED
    ) {
      countdownTimer.start();
    }
  }

  /**
   * Changes the chatbot mode.
   */
  public changeChatMode(): void {
    this.chatMode =
      this.chatMode === OpenAIChatbotModelEnum.LOCAL
        ? OpenAIChatbotModelEnum.OPENAI
        : OpenAIChatbotModelEnum.LOCAL;
  }

  /**
   * Changes the chatbot mockup mode.
   * @param mockupMode the mockup mode to set.
   */
  public changeMockupMode(mockupMode: string): void {
    switch (mockupMode) {
      case 'UNICREDIT':
        this.chatbotMockupMode = OpenAIChatbotMockupModeEnum.UNICREDIT;
        break;
      case 'BPER':
        this.chatbotMockupMode = OpenAIChatbotMockupModeEnum.BPER;
        break;
      case 'CARIGE':
        this.chatbotMockupMode = OpenAIChatbotMockupModeEnum.CARIGE;
        break;
      case 'OTHER_BANK':
        this.chatbotMockupMode = OpenAIChatbotMockupModeEnum.OTHER_BANK;
        break;
      case 'ALL':
      default:
        this.chatbotMockupMode = OpenAIChatbotMockupModeEnum.ALL;
        break;
    }
  }

  /**
   * Changes the chatbot mockup mode.
   * @param event
   */
  public onMockupChange(event): void {
    this.chatbotMockupMode = event.detail.value;
  }

  /**
   * Gets the chatbot language to use.
   * @param language the language to use.
   * @private
   */
  private getChatbotLanguageToUse(
    language: string
  ): OpenAIChatbotLanguageSupportEnum {
    switch (language) {
      case 'it':
        return OpenAIChatbotLanguageSupportEnum.IT;
      case 'en':
        return OpenAIChatbotLanguageSupportEnum.EN;
      default:
        return OpenAIChatbotLanguageSupportEnum.IT;
    }
  }

  /**
   * Function when the like is pressed.
   * @param eventData
   */
  public onLikePress(eventData: { like: boolean, message: ChatbotMessage }) {
    if (eventData.message !== null) {
      this.messages.push(eventData.message);
    }
  }

  /**
   * Function when the dislike is pressed.
   * @param eventData
   */
  public onDislikePress(eventData: { dislike: boolean, message: ChatbotMessage }) {
    if (eventData.message !== null) {
      this.messages.push(eventData.message);
    }
  }

  private onRdaRequired(message: string, result: ChatbotResponse): void {
    if (!result.rdaActive) {
      this.pushMessageRdaNotActive(message);
    } else {
      this.storeChatbotRda();
      this.pushMessageRda(message);
    }
  }

  private pushMessageRdaNotActive(message: string): void {
    this.messages.push(new ChatbotMessage(
      false, this.rdaMessageClosedTime,
      false, false, false, null, message, false));
  }

  private pushMessageRda(message: string): void {
    this.messages.push(new ChatbotMessage(
      false, this.rdaMessageOk,
      false, false, false, null, message, false, null,
      `/support-request/new?fromChatbot=true&societyId=${this.society.id}`,
      "cliccando qui"));
  }

  private storeChatbotRda() {
    const messages = this.lastMessages(this.rdaMaxMessages);
    const chatbotRda = new ChatbotRda(this.sessionId, messages);
    window.localStorage.setItem("rdaMessage", chatbotRda.toJson());
  }

  // Kinda hacky
  private lastMessages(num: number): ChatbotRdaMessage[] {
    return this.messages
      .slice(2)
      .filter(message => !message.linkUrl)
      .slice(- 2 * num)
      .map(message => new ChatbotRdaMessage(message));
  }

}
