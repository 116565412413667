<div class="row justify-content-between">
  <div class="col" style="z-index: 250">
    <div class="row" style="flex-wrap:nowrap">
      <div class="col-auto">
        <div class="welcomeText">
          Benvenuto <span *ngIf="user">{{user.firstName}} {{user.lastName}}</span>
          (<a (click)="logout()">Logout</a>)
        </div>
      </div>
      <div class="col-auto" style="display:flex;align-items:center;">
        <div class="sep vertical"></div>
      </div>
      <div class="col d-flex align-items-center">
        <div *ngIf="selectedSociety" class="welcomeText header-section" style="padding-right:1.5rem">
          <span (click)="openModal()" class="welcomeText header-section pointer pr-1">
            <img src="assets/images/icons/cambio-gruppo.svg" ngbTooltip="Cambia società" fill = "white" class="group-change-icon menu-icon header-icon">
          </span>
          <span>{{selectedSociety.name}}</span>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col">
    <div class="row" style="flex-wrap:nowrap">
      <app-uploader-queue></app-uploader-queue>
      <div>
        <app-downloader-queue></app-downloader-queue>
        <pwc-downloader-queue></pwc-downloader-queue>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="rightHeader d-flex flex-row-reverse">
      <div class="d-inline-block pointer" ngbDropdown #myDrop="ngbDropdown" display="dynamic" placement="bottom-right">
        <div class="baloon" ngbDropdownToggle id="dropdownManual">
          {{userInitials|uppercase}}
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button ngbDropdownItem (click)="navigateToChangePassword()">Cambio password</button>
          <ng-container *canAccess="AuthorityCodeEnum.ROLE_ECOBONUS_ACCESS">
            <button ngbDropdownItem *ngIf="environment.configuration.dynamicFaq?.active" (click)="navigateToFaq()">FAQ e
              Richieste di Assistenza</button>
          </ng-container>
          <button *ngIf="environment.configuration.dynamicFaq?.active" ngbDropdownItem
            (click)="navigateToMySupportRequests()">Le mie Richieste di Assistenza</button>
          <button ngbDropdownItem *ngIf="environment.configuration.showPrivacy"
            (click)="navigateToPrivacyPolicy()">Privacy
            policy</button>
          <button ngbDropdownItem *ngIf="environment.configuration.showPrivacy"
            (click)="navigateToTermsAndConditions()">Terms and
            conditions</button>
          <a ngbDropdownItem
            *ngIf="!environment.configuration.showPrivacy && environment.configuration.termsAndConditionFileName != ''"
            download="Terms&Conditions.pdf"
            [href]="'/assets/downloads/termsAndCondition/' + environment.configuration.termsAndConditionFileName"
            target="_blank">
            <span>Terms and conditions</span>
          </a>
          <button ngbDropdownItem (click)="logout()">Logout</button>
        </div>
      </div>

      <div class="notification-bell mx-3" routerLink="/notifications"
        [ngbTooltip]="notificationCount > 0 ? 'Ci sono nuove notifiche' : 'Non ci sono nuove notifiche'"
        style="position:relative;">
        <span class="badge badge-danger pointer" style="position:absolute;top:-50%;right:0;"
          *ngIf="notificationCount>0">{{ notificationCount }}</span>
        <img src="/assets/images/icons/bell.svg" class="notification-image">
      </div>

      <div class="mx-3" *ngIf="manualFileName">
        <a [href]="'/assets/downloads/manual/' + manualFileName" target="_blank">
          <img src="/assets/images/icons/manual_b2b.svg" ngbTooltip="{{'header.user-manual' | translate }}"
            class="small-icon">
        </a>
      </div>

      <div class="mx-3" *ngIf="faqFileName && !environment.configuration.dynamicFaq?.active">
        <a [href]="'/assets/downloads/faq/' + faqFileName" target="_blank">
          <img src="/assets/images/icons/help.svg" ngbTooltip="FAQ" class="small-icon">
        </a>
      </div>

      <div class="d-inline-block" *ngIf="secondaryLogo">
        <img [src]="secondaryLogo" alt="" class="logo-secondary">
      </div>

    </div>
  </div>
</div>

<app-society-selection #societySelection
  bindLabel="name" [currentSociety]="selectedSociety" (changeSociety)="setCurrentSociety($event)">
</app-society-selection>