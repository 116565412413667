import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-privacy-policy-text',
  templateUrl: './privacy-policy-text.component.html',
  styleUrls: ['./privacy-policy-text.component.scss']
})
export class PrivacyPolicyTextComponent implements OnInit {

  constructor(private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  back(): void {
    this.navigation.back()
  }

}
