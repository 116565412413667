import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutePath } from './enums/route-path.enum';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthenticationGuard, AuthorizationGuard, AutologinGuard, PasswordManagerGuard } from '@pwc-ecobonus/security';
import { RedirectService } from '@pwc-ecobonus/common';
import { AuthorityCodeEnum } from './enums/security/authority-code.enum';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyTextComponent } from './pages/privacy-policy-text/privacy-policy-text.component';
import { AcceptTermsGuard } from './accept-terms.guard';
import { LoginFaqComponent } from './pages/login/login-faq/login-faq.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: RoutePath.LOGIN,
    loadChildren: () => import('./pages/login/login.module').then(
      m => m.LoginModule
    )
  },
  {
    path: RoutePath.LOGIN_FAQ,
    component: LoginFaqComponent
  },
  {
    path: RoutePath.ACCESS_PROBLEM,
    loadChildren: () => import('./pages/help-request-login/support-request-login.module').then(
      m => m.SupportRequestLoginModule
    )
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AutologinGuard, AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard],
    canActivateChild: [AutologinGuard, AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard],
    children: [
      {
        path: '',
        redirectTo: RoutePath.PROJECT,
        pathMatch: 'full'
      },
      {
        path: RoutePath.DOSSIER,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/dossier/dossier.module').then(
          m => m.DossierModule
        )
      },
      {
        path: RoutePath.PROJECT,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/project/project.module').then(
          m => m.ProjectModule
        )
      },
      {
        path: RoutePath.FAQ_LIST,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/faq/faq.module').then(
          m => m.FaqModule
        )
      },
      {
        path: RoutePath.PDC,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/access-doc/access-doc.module').then(
          m => m.AccessDocModule
        )
      },
      {
        path: RoutePath.SUPPORT_REQUEST,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/help-request/support-request.module').then(
          m => m.SupportRequestModule
        )
      },
      {
        path: RoutePath.SUPPORT_REQUEST_LOGIN,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/help-request-login/support-request-login.module').then(
          m => m.SupportRequestLoginModule
        )
      },
      {
        path: RoutePath.ADVISORY,
        loadChildren: () => import('./pages/advisory/advisory.module').then(
          m => m.AdvisoryModule
        )
      },
      {
        path: RoutePath.COMMUNICATION,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/communication/communication.module').then(
          m => m.CommunicationModule
        )
      },
      {
        path: RoutePath.LIVE_ASSISTANT,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/live-assistant/live-assistant.module').then(
          m => m.LiveAssistantModule
        )
      },
      {
        path: RoutePath.REPORTS,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/reports/reports.module').then(
          m => m.ReportsModule
        ),
        data: {
          authorities: [
            AuthorityCodeEnum.ROLE_ECOBONUS_DELIVERY_EXPORT,
            AuthorityCodeEnum.ROLE_ECOBONUS_DIONAS_EXPORT,
            AuthorityCodeEnum.ROLE_ECOBONUS_GDO_EXPORT,
            AuthorityCodeEnum.ROLE_ECOBONUS_ESCO_EXPORT,
            AuthorityCodeEnum.ROLE_ECOBONUS_CARIGE_EXPORT,
            AuthorityCodeEnum.ROLE_ECOBONUS_ATD_WEB_EXPORT
          ]
        }
      },
      {
        path: RoutePath.CONFIGURATION,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/configuration/configuration.module').then(
          m => m.ConfigurationModule
        ),
      },
      {
        path: RoutePath.TAX_CREDIT,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/tax-credit/tax-credit.module').then(
          m => m.TaxCreditModule
        ),
      },
      {
        path: RoutePath.NOTIFICATION,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/notification/notification.module').then(
          m => m.NotificationModule
        ),
      },
      {
        path: RoutePath.OWNERSHIP,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/ownership/ownership.module').then(
          m => m.OwnershipModule
        ),
      },
      {
        path: RoutePath.BLACKLIST,
        // canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/blacklist/blacklist.module').then(
          m => m.BlacklistModule
        ),
      },
      {
        path: RoutePath.INTEGRATION_DASHBOARD,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/integration-dashboard/integration-dashboard.module').then(
          m => m.IntegrationDashboardModule
        ),
      },
      {
        path: RoutePath.UNIT_INTEGRATION_DASHBOARD,
        canActivate: [AcceptTermsGuard],
        loadChildren: () => import('./pages/unit-integration-dashboard/unit-integration-dashboard.module').then(
          m => m.UnitIntegrationDashboardModule
        ),
      },
      {
        path: RoutePath.ACCEPT_PRIVACY_POLICY,
        component: PrivacyPolicyComponent
      },
      {
        path: RoutePath.TERMS_AND_CONDITIONS,
        component: TermsAndConditionsComponent
      },
      {
        path: RoutePath.PRIVACY_POLICY,
        component: PrivacyPolicyTextComponent
      },
    ],
    data: {
      authorities: [AuthorityCodeEnum.ROLE_ECOBONUS_ACCESS],
      redirect: [RoutePath.B2C]
    },
  },
  {
    path: RoutePath.B2C,
    redirectTo: `/${RoutePath.REDIRECT}?url=${window.origin}/b2c/&=_self`,
    pathMatch: 'full',
    data: {
      authorities: [AuthorityCodeEnum.ROLE_ECOBONUS_B2C_ACCESS],
      redirect: [RoutePath.LOGIN]
    }
  },
  {
    path: RoutePath.REDIRECT,
    canActivate: [RedirectService],
    loadChildren: () => import('./pages/login/login.module').then(
      m => m.LoginModule
    )
  },

  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
