import { ChatbotInteractionRateEnum } from '../enums/chatbot-interaction-rate.enum';

export class Rate {

  private conversationId: string;
  private messageId: string;
  private rate: ChatbotInteractionRateEnum;

  constructor(conversationId: string, messageId: string, userRate: ChatbotInteractionRateEnum) {
    this.conversationId = conversationId;
    this.messageId = messageId;
    this.rate = userRate;
  }
}
