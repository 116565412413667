import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SocietiesService, Society } from '@pwc-ecobonus/common';
import { AuthorizationService } from '@pwc-ecobonus/security';
import {ModalComponent} from '@pwc-ecobonus/ui';
import { piCheck, PwcIconsLibrary} from '@pwc-ecobonus/icons';
import { StarredSocietyService } from 'src/app/services/starred-society.service';

@Component({
  selector: 'app-society-selection',
  templateUrl: './society-selection.component.html',
  styleUrls: ['./society-selection.component.scss']
})
export class SocietySelectionComponent implements OnInit {

  @Input() currentSociety: Society;
  @Input() societies: Society[] = [];
  @Output() changeSociety: EventEmitter<Society> = new EventEmitter<Society>();
  @ViewChild('societySelectionModal') societySelectionModal: ModalComponent;

  filterText: string = null;
  selectedSociety: Society;
  filteredSocieties: Society[];
  starredSociety: number = null;

  constructor(
    private societiesService: SocietiesService,
    private iconsLibrary: PwcIconsLibrary,
    private starredSocietyService: StarredSocietyService
  ) {
    this.iconsLibrary.registerIcons(
      [piCheck]
    );
  }

  ngOnInit(): void {
  }

  public open() {
    this.filterText = '';
    this.societies = [];
    this.filteredSocieties = [];

    this.updateStarredSociety();

    this.societiesService.getUserCompany()
      .subscribe((data: Society[]) => {
        this.societies = data;
        this.filteredSocieties = this.societies;
      });
    this.societySelectionModal.open();
  }

  public selected(society: Society) {
    this.changeSociety.emit(society);
    this.societySelectionModal.close();
  }

  public onChangeFilters() {
    this.filteredSocieties = this.societies.filter(society => society.name.toLowerCase().includes(this.filterText.toLowerCase()));
  }

  unstarSociety(id:number): void {
    this.starredSociety = null;
    this.starredSocietyService.unstarSociety(id).subscribe(() => {
      this.updateStarredSociety();
    });
  }

  starSociety(id:number): void {
    this.starredSociety = id;
    this.starredSocietyService.starSociety(id).subscribe(() => {
      this.updateStarredSociety();
    });
  }

  updateStarredSociety(callback?): void {
    this.starredSocietyService.getStarredSociety().subscribe((society: Society) => {
      this.starredSociety = society.id;
      if (callback) {
        callback();
      }
    });
  }
}
