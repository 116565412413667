import { ChatbotMessage } from "./chatbot-message";

export class ChatbotRdaMessage {
    readonly isUserMessage: boolean;
    readonly text: string;

    constructor(message: ChatbotMessage) {
        this.isUserMessage = message.isUserMessage;
        this.text = message.text;
    }
}