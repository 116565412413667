import {AuthenticationOptions} from '@pwc-ecobonus/security';
import {environment} from '../../environments/environment';

export class AuthenticationServiceConfig implements AuthenticationOptions {
  cookieDomain = environment.cookieDomain;
  cookiePath = environment.cookiePath;
  frontendUrl = environment.frameworkUrl;
  loginUrl = environment.frameworkUrl;
  url = environment.authUrl;
  secureFlag = environment.secureFlag;
}
