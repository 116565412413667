import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, DOWNLOAD_BASEURL } from '@pwc-ecobonus/common';
import { LayoutModule, UIModule } from '@pwc-ecobonus/ui';
import { PwcIconsModule } from '@pwc-ecobonus/icons';
import { FormsModule } from '@pwc-ecobonus/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { HttpLoaderFactory, I18nModule } from '@pwc-ecobonus/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ErrorInterceptor, JwtInterceptor, SecurityModule, HTTP_PUBLIC_URLS } from '@pwc-ecobonus/security';
import { environment } from '../environments/environment';
import { AuthenticationServiceConfig } from './security/authentication-service-config';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { NavMenuComponent } from './components/layout/nav-menu/nav-menu.component';
import { UploaderQueueComponent } from './components/uploader-queue/uploader-queue.component';
import { DownloaderQueueComponent } from './components/downloader-queue/downloader-queue.component';
import { NgLoggerModule } from '@nsalaun/ng-logger';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyTextComponent } from './pages/privacy-policy-text/privacy-policy-text.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpInterceptorService } from './interceptors/http-interceptor.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AccessDocComponent } from './pages/access-doc/access-doc.component';
import { SocietySelectionComponent } from './components/society-selection/society-selection.component';
import { ChatbotModule } from './pages/chatbot/chatbot.module';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
@NgModule({ declarations: [
        AppComponent,
        LayoutComponent,
        HeaderComponent,
        SocietySelectionComponent,
        NavMenuComponent,
        UploaderQueueComponent,
        DownloaderQueueComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        PrivacyPolicyTextComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        SecurityModule,
        ChatbotModule,
        UIModule,
        PwcIconsModule,
        FormsModule,
        NgSelectModule,
        LayoutModule,
        ToastrModule.forRoot({
            timeOut: 10000
        }),
        I18nModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxSpinnerModule,
        NgIdleKeepaliveModule.forRoot(),
        NgLoggerModule.forRoot(environment.logLevel),
        LoggerModule.forRoot({ level: NgxLoggerLevel.ERROR }),
        NgxUiLoaderModule.forRoot({
            bgsColor: '#eb8c00',
            bgsOpacity: 0.5,
            bgsPosition: 'bottom-right',
            bgsSize: 60,
            bgsType: 'ball-spin-clockwise',
            blur: 1,
            delay: 0,
            fastFadeOut: true,
            fgsColor: '#eb8c00',
            fgsPosition: 'center-center',
            fgsSize: 40,
            fgsType: 'cube-grid',
            gap: 24,
            logoPosition: 'center-center',
            logoSize: 120,
            logoUrl: '',
            masterLoaderId: 'master',
            overlayBorderRadius: '0',
            overlayColor: 'rgba(40, 40, 40, 0.8)',
            pbColor: 'red',
            pbDirection: 'ltr',
            pbThickness: 3,
            hasProgressBar: false,
            text: '',
            textColor: '#FFFFFF',
            textPosition: 'center-center',
            maxTime: -1,
            minTime: 300
        })], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: 'FRAMEWORK_BASEURL', useValue: environment.authUrl },
        { provide: 'AUTH_OPTIONS', useClass: AuthenticationServiceConfig, multi: false },
        { provide: 'API_BASEURL', useValue: environment.apiUrl },
        { provide: DOWNLOAD_BASEURL, useValue: environment.apiUrl },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_PUBLIC_URLS, useValue: ['/tooltip_dictionary'] },
        { provide: APP_BASE_HREF, useValue: '/' },
        CookieService,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
