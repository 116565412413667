import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ChatbotMessage } from '../../models/chatbot-message';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../../services/chatbot.service';
import { timer } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';

/**
 * This component is used to display a message.
 * It is used by the chatbot component.
 *
 * @author Mc Ayrton Aragon
 */
@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class MessageDetailComponent {

  @Input()
  public message: ChatbotMessage;

  @Output() likeRate = new EventEmitter<{ like: boolean, message: ChatbotMessage }>();
  @Output() dislikeRate = new EventEmitter<{ dislike: boolean, message: ChatbotMessage }>();

  public tooltipPlaceholder: String;
  public likePlaceholder: string;
  public dislikePlaceholder;

  private readonly chatbotErrorTimeoutInSeconds: number = 30;

  constructor(
    private chatbotService: ChatbotService,
    private translateService: TranslateService
  ) {
    this.tooltipPlaceholder = this.translateService.instant(
      'chatbot.description.report-wrong-answer');
    this.likePlaceholder = this.translateService.instant(
      'chatbot.action.rate.like');
    this.dislikePlaceholder = this.translateService.instant(
      'chatbot.action.rate.dislike');
  }

  /**
   * This returns the icon to use for the like button.
   */
  public checkLikeIcon(): string {
    return this.message.chatbotUserRate.like === true ?
      '/assets/images/icons/chatbot-thumbs-up-solid.svg'
      : '/assets/images/icons/chatbot-thumbs-up-regular.svg';
  }

  /**
   * This returns the icon to use for the dislike button.
   */
  public checkDislikeIcon(): string {
    return this.message.chatbotUserRate.dislike === true ?
      '/assets/images/icons/chatbot-thumbs-down-solid.svg'
      : '/assets/images/icons/chatbot-thumbs-down-regular.svg';
  }

  likePress(): void {
    if (this.message.chatbotUserRate.canVoteBeChanged) {
      this.message.chatbotUserRate.like = !this.message.chatbotUserRate.like;
      this.sendRating(true);
    }
  }

  dislikePress(): void {
    if (this.message.chatbotUserRate.canVoteBeChanged) {
      this.message.chatbotUserRate.dislike = !this.message.chatbotUserRate.dislike;
      this.sendRating(false);
    }
  }

  /**
   * Sends the rating to the chatbot.
   * @private
   */
  private sendRating(isLikeOrDislike: boolean): void {
    this.message.chatbotUserRate.canVoteBeChanged = false;
    const rateMessageOnDestroy$ = timer((this.chatbotErrorTimeoutInSeconds + 5) * 1000);
    this.chatbotService.postUserRate(this.message.chatbotUserRate)
      .pipe(
        timeout(this.chatbotErrorTimeoutInSeconds * 1000),
        takeUntil(rateMessageOnDestroy$))
      .subscribe(() => {
        if (isLikeOrDislike) {
          this.likeRate.emit({ like: this.message.chatbotUserRate.like, message: null });
        } else {
          this.dislikeRate.emit({ dislike: this.message.chatbotUserRate.dislike, message: null });
        }
      },
        () => {
          this.message.chatbotUserRate.resetFlags();
          const errorMessage = new ChatbotMessage(
            false,
            this.translateService.instant(
              'chatbot.description.error-rate-message-frontend'
            ),
            false,
            false,
            false,
            null,
            '',
            false
          );
          if (isLikeOrDislike) {
            this.likeRate.emit({ like: this.message.chatbotUserRate.like, message: errorMessage });
          } else {
            this.dislikeRate.emit({ dislike: this.message.chatbotUserRate.dislike, message: errorMessage });
          }
        });
  }
}
