import {Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {I18nService, Language} from '@pwc-ecobonus/i18n';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {TooltipService} from "./services/tooltip.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tls-ecobonus-fe';

  languages: Language[] = [];
  currentLanguage: string;

  appVersion = environment.appVersion;

  constructor(
    private i18n: I18nService,
    private translate: TranslateService,
    private tooltips: TooltipService
  ) {
    translate.setDefaultLang('it');
  }

  ngOnInit(): void {
    this.i18n.lang$.subscribe((lang: string) => {
      this.currentLanguage = lang;
    });
    this.tooltips.getTooltip().subscribe();
  }

  setLanguage(language: string): void {
    this.translate.use(language.toLowerCase());
  }
}
