<div class="card full-card shadow-sm my-4 card-v-center">
   <div class="card-body">

   <div class="row d-flex flex-row justify-content-center align-items-center form-inline">
      <label class="" for="termsAccepted" translate>
         <span translate>privacy.message</span>
         <a routerLink="/terms-and-conditions" class="" translate>privacy.terms-and-conditions
         </a>
         <span translate>privacy.and</span>
         <a routerLink="/privacy-policy" class="" translate>privacy.privacy-policy
         </a>
      </label>
      
      <input type="checkbox" [(ngModel)]="termsAccepted" name="termsAccepted" tabindex="-1"
         class="ml-1 no-shadow form-input input-with-border">
      <div class="invalid-feedback col-12 text-center" *ngIf="!termsAccepted" translate>
         privacy.mandatory-error
      </div>
   </div>
   <div class="mt-4 mb-4 row d-flex flex-row justify-content-center">
   
      <button class="btn btn-primary btn-rounded text-nowrap" (click)="acceptTerms()" [disabled]="!termsAccepted"
         style="width: 150px">
         <span class="d-inline-block" translate>
            privacy.action
         </span>
      </button>
   </div>
</div>