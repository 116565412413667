import {Component, OnInit} from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {faAngleDown, faAngleUp, faCheckCircle, faCircleNotch, faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FileItem, FileUploader} from '@pwc-ecobonus/forms';

@Component({
  selector: 'app-uploader-queue',
  templateUrl: './uploader-queue.component.html',
  styleUrls: ['./uploader-queue.component.scss']
})
export class UploaderQueueComponent implements OnInit {
  uploader: FileUploader;
  listVisible = null;

  faCircleNotch = faCircleNotch;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  faTimes = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  constructor(private uploaderService: UploadService) {
  }

  ngOnInit(): void {
    this.uploader = this.uploaderService.uploader;
  }

  get isPending(): boolean {
    return this.uploaderService.isPending;
  }

  get isUploading(): boolean {
    return this.uploaderService.isUploading;
  }

  get isProcessing(): boolean {
    return this.uploaderService.progress === 100 && this.uploaderService.isUploading;
  }

  get totalItems(): number {
    return this.uploaderService.totalItemsCount;
  }

  get completedItems(): number {
    return this.uploaderService.completedItemsCount;
  }

  get progress(): string {
    return this.uploaderService.progress + '%';
  }

  get hasItems(): boolean {
    return this.uploaderService.hasItems;
  }

  get isError(): boolean {
    return this.uploaderService.isError;
  }

  get isSuccess(): boolean {
    return this.uploaderService.isSuccess;
  }

  emptyQueue() {
    this.uploaderService.removeAll();
  }

  hideList(): void {
    this.listVisible = false;
  }

  showList(): void {
    this.listVisible = true;
  }

  isListVisible(): boolean {
    if (this.listVisible != null) {
      return this.listVisible;
    }
    return this.uploaderService.isError;
  }

  closeDialog() {
    if (this.isSuccess || this.isError) {
      this.emptyQueue();
    }
    this.listVisible = null;
    this.uploader.cancelAll();
    this.uploader.clearQueue();
  }

  getErrorMessage(item: FileItem) {
    if (item.headers == null || !('error_type' in item.headers)) {
      return 'Errore nel caricamento';
    }
    const error = item.headers.error_type;
    if (error === 'max_size_exceeded') {
      return 'La dimensione del File supera limite massimo di 30MB';
    }
    if (error === 'extension_is_empty') {
      return 'Il File non ha estensione';
    }
    if (error === 'extension_is_not_allowed') {
      return 'L\'estensione del file caricato non è compresa tra i formati ammessi';
    }
    if (error === 'file_name_contains_exe') {
      return 'Il File ha estensione .exe';
    }
    if (error === 'duplicate_file_dossier_stage') {
      return 'E\' stato già caricato un documento con lo stesso nome';
    }
    return 'Errore nel caricamento';
  }
}
