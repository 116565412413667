<div *ngIf="downloaderService.queue.length" class="downloader-queue">
  <div class="downloader-queue-header d-flex justify-content-between">
    <div style="line-height: 22px">
      <span *ngIf="isDownloading">Download in corso...</span>
      <span *ngIf="isSuccess || isError">File pronto per il download</span>
    </div>
    <div class="downloader-queue-actions">
      <button class="btn btn-sm" type="button" (click)="hideList()" *ngIf="listVisible">
        <fa-icon [icon]="faAngleDown"></fa-icon>
      </button>
      <button class="btn btn-sm" type="button" (click)="showList()" *ngIf="!listVisible">
        <fa-icon [icon]="faAngleUp"></fa-icon>
      </button>
      <button class="btn btn-sm" type="button" (click)="closeDialog()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>

  <ul class="downloader-queue-file-list" *ngIf="downloaderService.hasItems && listVisible">
    <li *ngFor="let item of downloaderService.queue" class="downloader-queue-file d-flex justify-content-between">
      <div class="file-name" [ngClass]="{'text-danger': item.isError}">
        <button class="btn btn-sm" (click)="openFile(item)">{{item.fileName}}</button>
      </div>
      <div class="downloader-queue-icon">
        <!--[spin]="true"-->
        <fa-icon [icon]="faCircleNotch" *ngIf="item.isDownloading"></fa-icon>
        <fa-icon [icon]="faTimesCircle" *ngIf="item.isError && !item.isDownloading" class="text-danger"></fa-icon>
        <fa-icon [icon]="faCheckCircle" *ngIf="item.isSuccess && !item.isDownloading" class="text-success"></fa-icon>
      </div>
    </li>
  </ul>
</div>

<a #download
   [href]="sanitizeUrl(downloadUrl)"
   [download]="downloadName"
   target="_blank"
   style="display:none!important"></a>
