import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@pwc-ecobonus/security';
import { User } from '@pwc-ecobonus/common';

import { environment } from 'src/environments/environment';
import { ChatbotResponse } from '../models/chatbot-response';
import { OpenAIChatbotModelEnum } from '../enums/open-a-i-chatbot-model.enum';
import { OpenAIChatbotLanguageSupportEnum } from '../enums/open-a-i-chatbot-language-support.enum';
import { ChatbotUserRate } from '../models/chatbot-user-rate';
import { Rate } from '../models/rate';
import { OpenAIChatbotMockupModeEnum } from '../enums/open-a-i-chatbot-mockup-mode.enum';
import { ChatbotHealthCheckEnum } from '../enums/chatbot-health-check.enum';
import { ChatbotSettings } from '../models/chatbot-settings';

/**
 * This service is used to manage the chatbot REST.
 * It is used by the chatbot component.
 *
 * @author Mc Ayrton Aragon
 */
@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  /**
   * The user.
   */
  private user: User;

  /**
   * The user initials.
   */
  private userInitials: string = '';

  /**
   * The base url.
   */
  private baseUrl: string = environment.apiUrl;

  /**
   * The endpoint for send/receive message.
   */
  private endpoint: string = 'chatbot/message';

    /**
   * The endpoint for send/receive message.
   */
    private configurationEndpoint: string = 'chatbot';


  /**
   * The endpoint for send rating
   */
  private rateEndpoint: string = 'chatbot/rate';


  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.initLoggedUser();
  }

  /**
   * Sends a message to the chatbot.
   * @param sessionId the session id
   * @param messageId the message id
   * @param message the message to send
   * @param model the model to use
   * @param language the language to use
   * @param mockupMode the chatbot mockup to use
   */
  public post(sessionId: string, messageId: string, message: string,
              model: OpenAIChatbotModelEnum, language: OpenAIChatbotLanguageSupportEnum,
              mockupMode: OpenAIChatbotMockupModeEnum):
    Observable<ChatbotResponse> {
    return this.http.post<ChatbotResponse>(`${ this.baseUrl }/${ this.endpoint }`,
      {
        sessionId,
        messageId,
        message,
        model,
        language,
        mockupMode
      });
  }

  /**
   * Obtains the logged user information.
   * @private
   */
  private initLoggedUser(): void {
    this.authenticationService.dataUser()
      .subscribe(
        (user: User) => {
          this.user = user;
          if (this.user != null) {
            if (this.user.firstName != null) {
              this.userInitials += this.user.firstName.substr(0, 1);
            }
            if (this.user.lastName != null) {
              this.userInitials += this.user.lastName.substr(0, 1);
            }
          } else {
            this.userInitials = 'you';
          }
        });
  }

  /**
   * Obtains a different greeting message.
   */
  public getGreetingMessage(): Observable<string> {
    return this.http.get(`${ this.baseUrl }/${ this.endpoint }/greeting`)
      .pipe(
        map(x => {
          return (x as ChatbotResponse).response;
        })
      );
  }

  /**
   * Obtains a different closing message.
   */
  public getClosingMessage(): Observable<string> {
    return this.http.get(`${ this.baseUrl }/${ this.endpoint }/closing-message`)
      .pipe(
        map(x => {
          return (x as ChatbotResponse).response;
        })
      );
  }

  /**
   * Obtains the chatbot settings for this user.
   */
  public getSettings(): Observable<ChatbotSettings> {
    return this.http.get(`${ this.baseUrl }/${ this.configurationEndpoint }/settings`)
      .pipe(
        map(x => {
          return (x as ChatbotSettings);
        })
      );
  }

  /**
   * Send the user's rate for the conversation
   * @param rateReport the rate given to the session by the user
   */
  public postUserRate(rateReport: ChatbotUserRate): Observable<string> {
    const rate = new Rate(rateReport.sessionId, rateReport.messageId, rateReport.getRealRateValue());
    return this.http.post<string>(`${ this.baseUrl }/${ this.rateEndpoint }`, rate);
  }

  /**
   * Send a health check to the service.
   * Note that both KO and ERROR are mapped as ERROR.
   */
  public healthCheck(): Observable<ChatbotHealthCheckEnum> {
    return this.http.get<ChatbotHealthCheckEnum>(`${this.baseUrl}/${this.endpoint}/health-check`)
      .pipe(
        map((health: ChatbotHealthCheckEnum) => {
          switch (health) {
            case ChatbotHealthCheckEnum.OK:
              return ChatbotHealthCheckEnum.OK;
            case ChatbotHealthCheckEnum.DISABLED:
              return ChatbotHealthCheckEnum.DISABLED;
            case ChatbotHealthCheckEnum.KO:
            case ChatbotHealthCheckEnum.KO:
            default:
              return ChatbotHealthCheckEnum.ERROR;
          }
        })
      );
  }

}
