
<pwc-modal #societySelectionModal id="societySelectionModal" class="selection-modal">
    <div modal-title class="d-flex flex-row justify-content-between align-items-baseline">
      <span translate>header.modals.society-selection.title</span>
    </div>
    <div modal-body>
      
      <div class="d-flex flex-row pt-2">
        <div class="col-12 form-group mr-4">
          <input type="text" class="form-control form-input input-with-border"
          [(ngModel)]="filterText" (ngModelChange)="onChangeFilters()" placeholder="Cerca società...">
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <ul class="mt-2 list-group">
              <ng-container *ngFor="let society of filteredSocieties">
                <li class="list-group-item pointer society" [ngClass]="currentSociety && society.id === currentSociety.id ? 'selected' : ''">
                  <div class="row">
                    <div class="col" style="padding:0; align-self:center;" (click)="selected(society)">
                      <span class="selected-icon" *ngIf="currentSociety && society.id === currentSociety.id">
                        <pwc-icon name="check"></pwc-icon>
                      </span>
                      <span>{{society.name}}</span>
                    </div>
                    <div class="col-auto" style="padding:0">
                      <img *ngIf="society.id === starredSociety" ngbTooltip="Rimuovi predefinito" class="star-icon"
                        src="assets/images/icons/star-filled.svg" (click)="unstarSociety(society.id)" />
                      <img *ngIf="society.id !== starredSociety" ngbTooltip="Imposta come predefinita" class="star-icon pointer"
                        src="assets/images/icons/star.svg" (click)="starSociety(society.id)" />
                    </div>
                  </div>
                </li>
              </ng-container>
          </ul>
        </div>
      </div>

    </div>
  </pwc-modal>
  