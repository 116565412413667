import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faAngleDown, faAngleUp, faCheckCircle, faCircleNotch, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DownloaderService } from '../../services/downloader.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Logger } from '@nsalaun/ng-logger';
import { DownloadedItem } from '../../models/downloaded-item';

@Component({
  selector: 'app-downloader-queue',
  templateUrl: './downloader-queue.component.html',
  styleUrls: ['./downloader-queue.component.scss']
})
export class DownloaderQueueComponent implements OnInit {
  listVisible = false;

  downloadName: string;
  downloadUrl: string;

  faCircleNotch = faCircleNotch;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  faTimes = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  @ViewChild('download') downloadElement: ElementRef;

  constructor(public downloaderService: DownloaderService,
    private domSanitizer: DomSanitizer,
    private logger: Logger) {
  }

  ngOnInit(): void {
    this.downloaderService.onDownloadCompleted.subscribe((res: DownloadedItem) => {
      this.logger.debug('onDownloadCompleted');
      // this.openFile(res.blob, res.url, res.fileName);
      this.openFile(res);
    });
  }

  public openFile(item: DownloadedItem) {
    if (item.url == null) {
      return;
    }
    if ((window.navigator as any).msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      (window.navigator as any).msSaveOrOpenBlob(item.blob, item.fileName);
    } else {
      this.downloadUrl = URL.createObjectURL(new Blob([item.blob]));
      this.downloadName = item.fileName;

      setTimeout(() => {
        this.downloadElement.nativeElement.click();

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(this.downloadUrl);

          this.downloadUrl = null;
          this.downloadName = null;
        }, 100);
      }, 100);
    }
  }

  sanitizeUrl(url: string): SafeUrl {
    if (url == null) {
      return null;
    }

    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  public openFile1(blob: Blob, fileURL: string, fileName: string) {
    if (fileURL == null) {
      return;
    }
    if ((window.navigator as any).msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      const tempLink = window.document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = fileURL;
      tempLink.setAttribute('download', fileName);
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        // URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  get isError(): boolean {
    return this.downloaderService.isError;
  }

  get isDownloading(): boolean {
    return this.downloaderService.isDownloading;
  }

  get isSuccess(): boolean {
    return this.downloaderService.isSuccess;
  }

  hideList(): void {
    this.listVisible = false;
  }

  showList(): void {
    this.listVisible = true;
  }

  emptyQueue() {
    this.downloaderService.removeAll();
  }

  closeDialog() {
    if (this.isSuccess || this.isError) {
      this.emptyQueue();
    }
    this.hideList();
  }
}
