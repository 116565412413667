<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <!-- Form Section -->
  <div class="mx-0">
    <input
      type="text"
      name="message-text"
      class="form-control"
      autocomplete="off"
      ngModel
      style="height: 40px !important;"
      [placeholder]="formPlaceholder"
      [disabled]="isInputDisabled"
    />
  </div>
</form>
