import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '@pwc-ecobonus/common';
import {AuthenticationService} from '@pwc-ecobonus/security';
import {RoutePath} from 'src/app/enums/route-path.enum';
import {UserPrivacyServiceService} from 'src/app/services/user-privacy-service.service';
import {environment} from 'src/environments/environment';
import {BasePage} from '../base-page/base-page';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent extends BasePage implements OnInit {

  termsAccepted: boolean = false;
  user: User = null;
  environment = environment;


  constructor(
    private authenticationService: AuthenticationService,
    private userPrivacyServiceService: UserPrivacyServiceService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authenticationService.dataUser()
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  acceptTerms() {
    // save
    this.subscriptions.push(
      this.userPrivacyServiceService.accepPrivacy()
        .subscribe(data => {
          this.authenticationService.resetDataUserCache();

          this.router.navigate([RoutePath.SLASH]);
        })
    );
  }

}
